import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router,Routes,Route} from "react-router-dom"
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Authentication from './layouts/Authentication';
import Register from './pages/Register';
import ResetPwd from './pages/ResetPwd';
import ConfirmEmail from './pages/ConfirmEmail';
import Confirmation from './pages/Confirmation';
import Recover from './pages/Recover';
import { Authorized } from './layouts/Authorized';
import { Dashboard } from './pages/User/Dashboard';
import Orders from './pages/User/Orders';
import History from './pages/User/History';
import Customers from './pages/User/Customers';
import Financials from './pages/User/Financials';
import Settings from './pages/User/Settings';
import TransactionHistory from './pages/User/TransactionHistory';
import React from 'react';
import SwipeableTemporaryDrawer from './components/Drawers';
import CustomerHistory from './pages/User/CustomerHistory';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <React.Fragment>
        <SwipeableTemporaryDrawer />
        <Router>
          <Routes>
            <Route path="/" element={<Authentication />}>
                <Route  index element={<Login />} />
                <Route path="login" element={<Login />} />
                <Route   path="register" element={<Register />} />
                <Route   path="reset-password" element={<ResetPwd />} />
                <Route   path="confirm-email" element={<ConfirmEmail />} />
                <Route   path="confirmation" element={<Confirmation />} />
                <Route   path="recover" element={<Recover />} />
            </Route>
            <Route path="/user" element={<Authorized />}>
              <Route  index path="dashboard" element={<Dashboard />} />
              <Route   path="orders" element={<Orders />} />
              <Route   path="order-history" element={<Orders />} />
              <Route   path="customers" element={<Customers />} />
              <Route   path="financials" element={<Financials />} />
              <Route   path="settings" element={<Settings />} />
              <Route   path="transaction-history" element={<TransactionHistory />} />
              <Route   path="invoices" element={<TransactionHistory />} />
              <Route   path="customer-history" element={<CustomerHistory />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
        <Toaster />
    </React.Fragment>
  );
}

export default App;
