import { Box, Container, Grid, Typography } from "@mui/material"
import React from "react"
import { Outlet,Link } from "react-router-dom";
import { AuthIllustration, AuthWrapper, P } from "../../components";
import styles from "./style"
import Img from "../../images/bg.png"


const Authentication = () => {
    return(
        <Box>
            
            <Grid container>
                <AuthIllustration 
                    md={5} item
                    backgroundimg={Img}
                />
                <Outlet />
            </Grid>
        </Box>
    )
}

export default Authentication;