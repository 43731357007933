import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import {
  Container,
  CustomButton,
  CustomSelect,
  H1,
  Input,
  Label,
  P,
  CustomTimePicker,
  SearchableSelect,
} from ".";
import { AppColors } from "./AppColors";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CircleIcon from "@mui/icons-material/Circle";
import { useSelector, useDispatch } from "react-redux";
import { TOGGLE_DRAWER } from "../actions";
import { FormGroup, InputAdornment, MenuItem } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CreateIcon from "@mui/icons-material/Create";
import { openDrawer } from "../redux/drawerSlice";
import { CREATE_ORDER, ORDER_DETAILS } from "../redux/actionTypes";
import {
  Capitalize,
  ToastError,
  ToastSuccess,
  validateEmail,
} from "../utils/functions";
import { apiFunctions, useFetchClientInfo } from "../utils/api";
import { usePlacesWidget } from "react-google-autocomplete";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import numeral from "numeral";
import { useMutation, useQueryClient } from "react-query";
import moment from "moment";
import { useDebounce } from "use-debounce";

const CreateOrder = () => {
  const [tab, setTab] = React.useState("Recipient");
  const [user_id, setUserId] = React.useState(null);
  const queryClient = useQueryClient();

  const { data: client } = useFetchClientInfo(user_id);

  const { mutateAsync, isLoading } = useMutation(apiFunctions.createOrder);

  React.useEffect(() => {
    const store = JSON.parse(localStorage.getItem("user"));
    setUserId(store?.client_id?.id);
  }, []);

  const [inputValue, setInputValue] = React.useState("");
  const [searchValue] = useDebounce(inputValue, 200);

  const [pickupValue, setPickupValue] = React.useState("");
  const [pickupSearchValue] = useDebounce(pickupValue, 200);
  const [address_field_name, setAddressFieldName] = React.useState("");

  const [data, setData] = React.useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    no_of_items: "",
    item: "",
    delivery_address: "",
    lcda: "",
    pickup_address: "",
    pickup_time: "",
    payment_method: "",
    amount_to_collect: "",
    total_amount: "",
  });

  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesService({
      apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    });

  React.useEffect(() => {
    if (address_field_name === "pick_up_address")
      return getPlacePredictions({ input: pickupSearchValue });
    if (address_field_name === "delivery_address")
      return getPlacePredictions({ input: searchValue });
  }, [searchValue, pickupSearchValue]);

  const submitHandler = async () => {
    try {
      if (tab === "Recipient") {
        const required = [
          "first_name",
          "first_name",
          "last_name",
          "phone",
          "email",
          "no_of_items",
        ];
        let msg = "";
        for (const req of required) {
          if (!data[req] || data[req].toString().trim() === "") {
            msg = `Please provide your ${req.replaceAll("_", " ")}`;
            break;
          }
        }
        if (msg !== "") return ToastError(msg);
        if (!validateEmail(data.email))
          return ToastError("Please provide a valid email address");
        return setTab("Delivery");
      }
      if (tab === "Delivery") {
        const required = [
          "first_name",
          "pickup_time",
          "payment_method",
          "amount_to_collect",
        ];
        let msg = "";
        for (const req of required) {
          if (!data[req] || data[req].toString().trim() === "") {
            msg = `Please provide your ${req.replaceAll("_", " ")}`;
            break;
          }
        }
        if (msg !== "") return ToastError(msg);

        return setTab("Preview");
      }
      if (
        !data.delivery_address?.place_id ||
        !data.delivery_address?.place_id
      ) {
        return ToastError(
          "Please use the google address suggestion to fill the pick up address and the recipient address"
        );
      }
      let fd = {
        ...data,
        client_id: user_id,
        amount_to_collect: Number(numeral(data.amount_to_collect).format("0")),
        no_of_items: Number(numeral(data.no_of_items).format("0")),
        total_amount: Number(numeral(data.amount_to_collect).format("0")) + 1,
        customer_address: data.pickup_address?.description,
        pickup_time: moment(data.pickup_time).format("HH:mm"),
      };
      if (!data.item) delete fd["item"];
      await mutateAsync(fd);
      ToastSuccess("Success! Order has been created");
      queryClient.invalidateQueries("customers");
      queryClient.invalidateQueries("orders");
      queryClient.invalidateQueries("todays_orders");
      setData({
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        no_of_items: "",
        item: "",
        delivery_address: "",
        lcda: "",
        pickup_address: "",
        pickup_time: "",
        payment_method: "",
        amount_to_collect: "",
        total_amount: "",
      });
      setTab("Recipient");
    } catch (err) {
      ToastError(err.msg);
    }
  };

  return (
    <Box sx={{ width: 600 }} role="presentation">
      {tab === "Recipient" ? (
        <Container padding={"2rem 2rem"}>
          <H1 fontWeight={"bold"} fontSize={"1.252rem"}>
            {client?.data?.name ? `${Capitalize(client?.data?.name)}'s` : ""}{" "}
            Deliveries
          </H1>
          <Container
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            marginTop={"2rem"}
            width="60%"
          >
            {["Recipient", "Delivery"].map((item, index) => (
              <CustomButton
                variant="contained"
                width="8rem"
                mode={tab === item ? "danger" : ""}
                key={index}
                disabled={tab !== item}
              >
                <H1 variant="h6" fontSize={"0.82rem"} fontWeight={"bold"}>
                  {item}
                </H1>
              </CustomButton>
            ))}
          </Container>
          <Container container marginTop={"2rem"}>
            <Container
              display={"flex"}
              justifyContent={"space-between"}
              marginTop={"2rem"}
            >
              <Container item md={5.8}>
                <FormGroup>
                  <Label fontSize={"0.938rem"}>First Name *</Label>
                  <Input
                    placeholder="First Name"
                    value={data?.first_name || ""}
                    onChange={(e) =>
                      setData({ ...data, first_name: e.target.value })
                    }
                  />
                </FormGroup>
              </Container>
              <Container item md={5.8}>
                <FormGroup>
                  <Label fontSize={"0.938rem"}>Last Name *</Label>
                  <Input
                    placeholder="Last Name"
                    value={data?.last_name || ""}
                    onChange={(e) =>
                      setData({ ...data, last_name: e.target.value })
                    }
                  />
                </FormGroup>
              </Container>
            </Container>
            <Container
              display={"flex"}
              justifyContent={"space-between"}
              marginTop={"2rem"}
            >
              <Container item md={5.8}>
                <FormGroup>
                  <Label fontSize={"0.938rem"}>Phone number *</Label>
                  <Input
                    placeholder="Phone number"
                    value={data?.phone || ""}
                    onChange={(e) =>
                      setData({ ...data, phone: e.target.value })
                    }
                    type="number"
                  />
                </FormGroup>
              </Container>
              <Container item md={5.8}>
                <FormGroup>
                  <Label fontSize={"0.938rem"}>Email *</Label>
                  <Input
                    placeholder="Email address"
                    type="email"
                    value={data?.email || ""}
                    onChange={(e) =>
                      setData({ ...data, email: e.target.value })
                    }
                  />
                </FormGroup>
              </Container>
            </Container>
            <Container item md={5.8} marginTop={"2rem"}>
              <FormGroup>
                <Label fontSize={"0.938rem"}>Number of Items *</Label>
                <Input
                  placeholder="Number of items"
                  value={data?.no_of_items || ""}
                  onChange={(e) =>
                    setData({ ...data, no_of_items: e.target.value })
                  }
                  type="number"
                />
              </FormGroup>
            </Container>
            <Container item marginTop={"2rem"}>
              <FormGroup>
                <Label fontSize={"0.938rem"}>Delivery Items (Optional)</Label>
                <Input
                  placeholder="Delivery Items"
                  type="textarea"
                  rows={"4"}
                  multiline
                  value={data?.item || ""}
                  onChange={(e) => setData({ ...data, item: e.target.value })}
                />
              </FormGroup>
            </Container>

            <Container
              item
              marginTop={"4rem"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <CustomButton
                variant="contained"
                mode="danger"
                fullWidth
                marginbottom={"2rem"}
                onClick={submitHandler}
              >
                <H1 variant="h6" fontWeight={"bold"}>
                  Continue
                </H1>
              </CustomButton>
            </Container>
          </Container>
        </Container>
      ) : null}

      {tab === "Delivery" ? (
        <Container padding={"2rem 2rem"}>
          <H1 fontWeight={"bold"} fontSize={"1.252rem"}>
            {client?.data?.name ? `${Capitalize(client?.data?.name)}'s` : ""}{" "}
            Deliveries
          </H1>
          <Container
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            marginTop={"2rem"}
            width="60%"
          >
            {["Recipient", "Delivery"].map((item, index) => (
              <CustomButton
                variant="contained"
                width="8rem"
                mode={"danger"}
                key={index}
                onClick={() => setTab(item)}
              >
                <H1 variant="h6" fontSize={"0.82rem"} fontWeight={"bold"}>
                  {item}
                </H1>
              </CustomButton>
            ))}
          </Container>
          <Container alignItems={"center"} display={"flex"} marginTop={"1rem"}>
            <P variant="span" fontSize={"0.8rem"} marginRight={"0.2rem"}>
              <InfoIcon sx={{ color: AppColors.red }} fontSize="0.8rem" />
            </P>
            <P color={AppColors.red} variant="span" fontSize={"0.8rem"}>
              Please use the google address suggestion to fill the pick up
              address and the recipient address
            </P>
          </Container>
          <Container container marginTop={"2rem"}>
            <Container item md={12}>
              <FormGroup>
                <Label fontSize={"0.938rem"}>Recipient delivery address</Label>
                <SearchableSelect
                  placeholder="114b Toyin street, Herbert Macaulay Way"
                  isOptionEqualToValue={(option, value) => {
                    return option.place_id === value.place_id;
                  }}
                  onChange={(e, value) =>
                    setData({ ...data, delivery_address: value })
                  }
                  value={data?.delivery_address}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setAddressFieldName("delivery_address");
                    setInputValue(newInputValue);
                  }}
                  getOptionLabel={(option) =>
                    option?.description ? Capitalize(option?.description) : ""
                  }
                  options={
                    address_field_name === "delivery_address" &&
                    placePredictions &&
                    Array.isArray(placePredictions)
                      ? placePredictions
                      : []
                  }
                  loading={
                    address_field_name === "delivery_address" &&
                    isPlacePredictionsLoading
                  }
                />
              </FormGroup>
            </Container>
            <Container
              display={"flex"}
              justifyContent={"space-between"}
              marginTop={"2rem"}
            >
              <Container item md={5.8}>
                <FormGroup>
                  <Label fontSize={"0.938rem"}>
                    Restaurant outlet (Pickup)
                  </Label>
                  <SearchableSelect
                    placeholder="Merchant Name - Eti Osa"
                    isOptionEqualToValue={(option, value) => {
                      return option.place_id === value.place_id;
                    }}
                    onChange={(e, value) =>
                      setData({ ...data, pickup_address: value })
                    }
                    value={data?.pickup_address}
                    inputValue={pickupValue}
                    onInputChange={(event, newInputValue) => {
                      setAddressFieldName("pick_up_address");
                      setPickupValue(newInputValue);
                    }}
                    getOptionLabel={(option) =>
                      option?.description ? Capitalize(option?.description) : ""
                    }
                    options={
                      address_field_name === "pick_up_address" &&
                      placePredictions &&
                      Array.isArray(placePredictions)
                        ? placePredictions
                        : []
                    }
                    loading={
                      address_field_name === "pick_up_address" &&
                      isPlacePredictionsLoading
                    }
                  />
                </FormGroup>
              </Container>
              <Container item md={5.8}>
                <FormGroup>
                  <Label fontSize={"0.938rem"}>Pickup time</Label>
                  <CustomTimePicker
                    placeholder="HH:mm a"
                    onChange={(value) =>
                      setData({ ...data, pickup_time: value })
                    }
                    value={data?.pickup_time || ""}
                  />
                </FormGroup>
              </Container>
            </Container>
            <Container
              display={"flex"}
              justifyContent={"space-between"}
              marginTop={"2rem"}
            >
              <Container item md={5.8}>
                <FormGroup>
                  <Label fontSize={"0.938rem"}>Payment method</Label>
                  <CustomSelect
                    onChange={(e) =>
                      setData({ ...data, payment_method: e.target.value })
                    }
                    value={data?.payment_method || ""}
                  >
                    <MenuItem value="Already-Paid">Already Paid</MenuItem>
                    <MenuItem value="COD">COD</MenuItem>
                    <MenuItem value="Transfer">Transfer</MenuItem>
                  </CustomSelect>
                </FormGroup>
              </Container>
              <Container item md={5.8}>
                <FormGroup>
                  <Label fontSize={"0.938rem"}>
                    Amount to be collected (NGN)
                  </Label>
                  <Input
                    placeholder="2,200"
                    onChange={(e) =>
                      setData({ ...data, amount_to_collect: e.target.value })
                    }
                    value={
                      data?.amount_to_collect
                        ? numeral(data?.amount_to_collect).format("0,0")
                        : ""
                    }
                  />
                </FormGroup>
              </Container>
            </Container>
            <Container marginTop={"3rem"}>
              {[
                {
                  title: "Delivery Fee",
                  value: "₦1",
                },
                {
                  title: "Total Items",
                  value: data?.no_of_items
                    ? numeral(data.no_of_items).format("0,0")
                    : 0,
                },
                {
                  title: "Amount to be collected",
                  value: `₦${
                    data?.amount_to_collect
                      ? numeral(data?.amount_to_collect).format("0,0")
                      : "0"
                  }`,
                },
                {
                  title: "Total Amount",
                  value: `₦${
                    data?.amount_to_collect
                      ? numeral(
                          Number(numeral(data?.amount_to_collect).format("0")) +
                            1
                        ).format("0,0")
                      : "1"
                  }`,
                },
              ].map((item, index) => (
                <Container
                  display={"flex"}
                  justifyContent={"space-between"}
                  marginBottom={"1rem"}
                  key={index}
                >
                  <P fontSize={"1rem"} color={AppColors.grey}>
                    {item?.title}
                  </P>
                  <H1 variant="h6" fontSize={"1rem"} fontWeight={"bold"}>
                    {item?.value}
                  </H1>
                </Container>
              ))}
            </Container>

            <Container
              item
              marginTop={"4rem"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <CustomButton
                variant="contained"
                mode="danger"
                fullWidth
                marginbottom={"2rem"}
                onClick={submitHandler}
              >
                <H1 variant="h6" fontWeight={"bold"}>
                  Continue
                </H1>
              </CustomButton>
            </Container>
          </Container>
        </Container>
      ) : null}

      {tab === "Preview" ? (
        <Container padding={"2rem 2rem"}>
          <H1 fontWeight={"bold"} fontSize={"1.252rem"}>
            {client?.data?.name ? `${Capitalize(client?.data?.name)}'s` : ""}{" "}
            Deliveries
          </H1>
          <Container container marginTop={"2rem"}>
            <Container
              padding={"1rem 1.5rem"}
              backgroundColor={AppColors.greyBackground}
              borderRadius={"1rem"}
            >
              <Container
                display={"flex"}
                justifyContent={"space-between"}
                marginBottom={"2rem"}
                alignItems={"center"}
              >
                <H1 variant="h6" fontWeight={"bold"} fontSize={"0.875rem"}>
                  Customer delivery details:
                </H1>
                <CustomButton
                  width="40"
                  display={"flex"}
                  disabled={isLoading}
                  justifycontent={"space-between"}
                  alignitems={"center"}
                  onClick={() => setTab("Recipient")}
                >
                  <CreateIcon
                    fontSize="1rem"
                    sx={{ color: AppColors.purple }}
                  />
                  <P color={AppColors.purple}>Edit</P>
                </CustomButton>
              </Container>
              {[
                {
                  title: "Name",
                  value: `${
                    data?.first_name ? Capitalize(data?.first_name) : ""
                  } ${
                    data?.last_name ? Capitalize(data?.last_name) : ""
                  }`.trim(),
                },
                {
                  title: "Email",
                  value: data?.email,
                },
                {
                  title: "Phone",
                  value: data.phone,
                },
                {
                  title: "Recipient Address",
                  value: data?.delivery_address?.description,
                },
                {
                  title: "Pickup Address",
                  value: data?.pickup_address?.description,
                },
              ].map((item, index) => (
                <Container display={"flex"} marginBottom={"1rem"} key={index}>
                  <Container width={"40%"}>
                    <P color={AppColors.grey} fontSize={"0.827rem"}>
                      {item?.title}
                    </P>
                  </Container>
                  <Container width={"40%"}>
                    <P fontSize={"0.827rem"} color={AppColors.black}>
                      {item?.value}
                    </P>
                  </Container>
                </Container>
              ))}
            </Container>

            <Container
              marginTop={"3rem"}
              padding={"1rem 1.5rem"}
              borderRadius={"1rem"}
              border={`0.02rem solid ${AppColors.black}`}
            >
              {[
                {
                  title: "Delivery Fee",
                  value: "₦1",
                },
                {
                  title: "POD/Already Paid",
                  value: `₦${
                    data?.amount_to_collect
                      ? numeral(data?.amount_to_collect).format("0,0")
                      : "0"
                  }`,
                },
                {
                  title: "Total Amount",
                  value: `₦${
                    data?.amount_to_collect
                      ? numeral(
                          Number(numeral(data?.amount_to_collect).format("0")) +
                            1
                        ).format("0,0")
                      : "1"
                  }`,
                },
              ].map((item, index) => (
                <Container
                  display={"flex"}
                  justifyContent={"space-between"}
                  marginBottom={"1rem"}
                  key={index}
                >
                  <P fontSize={"0.875rem"} color={AppColors.grey}>
                    {item?.title}
                  </P>
                  <H1 variant="h6" fontSize={"0.875rem"} fontWeight={"bold"}>
                    {item?.value}
                  </H1>
                </Container>
              ))}
            </Container>

            <Container
              marginTop={"3rem"}
              padding={"1rem 1.5rem"}
              borderRadius={"1rem"}
              border={`0.02rem solid ${AppColors.black}`}
            >
              {[
                {
                  title: "Menu Review",
                },
                {
                  title: "Items",
                  value: data?.no_of_items
                    ? numeral(data.no_of_items).format("0,0")
                    : 0,
                },
              ].map((item, index) => (
                <Container
                  display={"flex"}
                  justifyContent={
                    item?.title === "Menu Review" ? "center" : "space-between"
                  }
                  marginBottom={"1rem"}
                  key={index}
                >
                  {item?.title !== "Menu Review" ? (
                    <React.Fragment>
                      <P fontSize={"1rem"} color={AppColors.grey}>
                        {item?.title}
                      </P>
                      <H1 variant="h6" fontSize={"1rem"} fontWeight={"bold"}>
                        {item?.value}
                      </H1>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <H1
                        variant="h6"
                        fontWeight={"bold"}
                        fontSize={"0.875rem"}
                      >
                        {item?.title}
                      </H1>
                    </React.Fragment>
                  )}
                </Container>
              ))}
            </Container>

            <Container
              item
              marginTop={"4rem"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <CustomButton
                variant="contained"
                mode="danger"
                fullWidth
                marginbottom={"2rem"}
                onClick={submitHandler}
                loading={isLoading}
                disabled={isLoading}
              >
                <H1 variant="h6" fontWeight={"bold"}>
                  Post Order Now
                </H1>
              </CustomButton>
            </Container>
          </Container>
        </Container>
      ) : null}
    </Box>
  );
};

const OrderDetails = () => {
  const order = useSelector((state) => state.order);
  return (
    <Box
      sx={{ width: 600 }}
      role="presentation"
      //onKeyDown={()=>dispatch(TOGGLE_DRAWER("ORDER_DETAILS"))}
    >
      <Container padding={"5rem 2rem"}>
        <H1 fontWeight={"bold"} fontSize={"1.252rem"}>
          #{order?.order_ref}
        </H1>
        <Container
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          marginTop={"1rem"}
          borderBottom={`0.1rem solid ${AppColors.line}`}
          paddingBottom={"1rem"}
        >
          <H1 color={AppColors.red} fontWeight={"bold"}>
            Rider : {order?.rider ? Capitalize(order?.rider.toString()) : null}
          </H1>
          {order?.status ? (
            <Container
              backgroundColor={AppColors.lightLime}
              borderRadius={"0.5rem"}
              width="6rem"
              padding={"0.2rem"}
            >
              <P color={AppColors.lime} textAlign={"center"}>
                Delivered
              </P>
            </Container>
          ) : null}
        </Container>
        <Container marginTop={"2rem"}>
          {[
            {
              title: "Delivery date",
              value: order?.created_at
                ? moment(order?.created_at).format("DD/MM/YYYY")
                : null,
            },
            // {
            //     title : "LCDA",
            //     value : "Ikeja"
            // },
            {
              title: "Transaction Type",
              value: order?.payment_method,
            },
            {
              title: "Amount to be collected:",
              value: `N${
                order?.amount_to_collect
                  ? numeral(order?.amount_to_collect).format("0,0")
                  : "0"
              }`,
            },
          ].map((item, i) => (
            <Container
              display={"flex"}
              key={i}
              justifyContent={"space-between"}
              alignItems={"center"}
              marginBottom={"1rem"}
              width={"80%"}
            >
              <P fontSize={"0.866rem"}>{item.title}</P>
              <Container width={"10rem"}>
                <H1 fontWeight={"bold"} fontSize={"0.913rem"}>
                  {item.value}
                </H1>
              </Container>
            </Container>
          ))}
        </Container>
        <Container marginTop={"2rem"}>
          <H1 color={AppColors.red} fontWeight={"bold"} fontSize={"0.901rem"}>
            Customer Details
          </H1>
          <Container
            padding={"1rem 1.5rem"}
            backgroundColor={AppColors.lightGrey}
            borderRadius={"1rem"}
            marginTop={"1rem"}
          >
            <H1 fontWeight={"bold"} fontSize={"0.901rem"}>
              {`${
                order?.customer?.first_name
                  ? Capitalize(order?.customer?.first_name)
                  : ""
              } ${
                order?.customer?.last_name
                  ? Capitalize(order?.customer?.last_name)
                  : ""
              }`.trim()}
            </H1>
            <Container
              display={"flex"}
              alignItems={"center"}
              marginTop={"1rem"}
            >
              <AlternateEmailIcon
                sx={{ marginRight: "0.5rem", fontSize: "0.788rem" }}
              />
              <P fontSize={"0.788rem"}>{order?.customer?.email}</P>
            </Container>
            <Container
              display={"flex"}
              alignItems={"center"}
              marginTop={"1rem"}
            >
              <LocalPhoneIcon
                sx={{ marginRight: "0.5rem", fontSize: "0.788rem" }}
              />
              <P fontSize={"0.788rem"}>{order?.customer?.phone}</P>
            </Container>
            <Container
              display={"flex"}
              alignItems={"center"}
              marginTop={"1rem"}
            >
              <LocationOnIcon
                sx={{ marginRight: "0.5rem", fontSize: "0.788rem" }}
              />
              <P fontSize={"0.788rem"}>{order?.delivery_address}</P>
            </Container>
          </Container>
        </Container>
        <Container
          display={"flex"}
          alignItems={"center"}
          marginTop={"2rem"}
          borderBottom={`0.1rem solid ${AppColors.line}`}
          paddingBottom={"1rem"}
        >
          <H1 color={AppColors.red} fontWeight={"bold"}>
            Order Tracker
          </H1>
        </Container>
        <Container>
          {[
            {
              color: AppColors.red,
              status: "Posted order",
              time: "3:45pm",
            },
            {
              color: AppColors.yellow,
              status: "Pickup order",
              time: "4:45pm",
            },
            {
              color: AppColors.lime,
              status: "In-Transit",
              time: "5:00pm",
            },
            {
              color: AppColors.lightGrey,
              status: "Completed",
              time: "6:45pm",
            },
          ].map((item, i) => (
            <Container
              key={i}
              width="50%"
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              marginTop={"1rem"}
            >
              <Container display={"flex"} width={"10rem"} alignItems={"center"}>
                <CircleIcon sx={{ color: item.color, marginRight: "1rem" }} />
                <P>{item.status}</P>
              </Container>
              <P color={AppColors.textGrey} fontSize={"0.676rem"}>
                {item.time}
              </P>
            </Container>
          ))}
        </Container>
      </Container>
    </Box>
  );
};

export default function SwipeableTemporaryDrawer() {
  const show = useSelector((state) => state.drawer);
  const dispatch = useDispatch();

  return (
    <div>
      <React.Fragment>
        <SwipeableDrawer
          anchor={"right"}
          open={show?.open}
          onClose={() => dispatch(openDrawer(ORDER_DETAILS))}
          onOpen={() => dispatch(openDrawer(ORDER_DETAILS))}
        >
          {show?.name === ORDER_DETAILS ? <OrderDetails /> : null}
          {show?.name === CREATE_ORDER ? <CreateOrder /> : null}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
