import { Avatar, FormGroup, IconButton } from "@mui/material";
import React, { useEffect, useRef } from "react"
import { Container, CustomButton, H1, Image, Label, P, PaperBox,Input, ResetPassword, ResetEmail, ResetPhone } from "../../../components"
import { AppColors } from "../../../components/AppColors";
import { SECURTY_TAB_LIST, SettingsTabList } from "../../../components/Constants";
import EditPNG from '../../../images/edit.png'
import BrandPNG from '../../../images/brand.png'
import InfoIcon from '@mui/icons-material/Info';
import ReactCropper from "../../../components/Cropper";
import { dataURLtoFile, Preview, ToastError, ToastSuccess } from "../../../utils/functions";
import { styles } from "./styles";
import { apiFunctions, useFetchClientInfo } from "../../../utils/api";
import { useMutation } from "react-query";


const Settings = () => {
    const [tab,setTab] = React.useState("Business Info")
    const [menu,setMenu] = React.useState("")
    const [open,setOpen] = React.useState(false)
    const [image,setImage] = React.useState(null)
    const [logo,setLogo] = React.useState(null)
    const [placeholder,setPlaceholder] = React.useState(null)
    const [user_id,setUserId] = React.useState(null)
    const [user,setUser] = React.useState(null)

    const [businessInfo,setBusinessInfo] = React.useState({})
    const upload = useRef(null)

    const {
        data : load,
        isLoading
    } = useFetchClientInfo(user_id)

    const {
        mutateAsync : updateClient,
        isLoading : updating
    } = useMutation(apiFunctions.updateClient)

    const {
        mutateAsync : uploadImage,
        isLoading : uploading
    } = useMutation(apiFunctions.uploadImage)

    const handleFilePicker = (e) => {
        setImage(Preview(e.target.files[0]))
        setOpen(true)
    }

    const openFilePicker = () => {
        upload.current.click()
    }

    const updateLogo = (file) => {
        setLogo(file)
        setOpen(false)
    }

    const handleSubmit = async () => {
        try{
            const required = [
                //"first_name",
                "name",
               // "last_name",
                "address",
                "country_id",
                "state",
                "email",
                "phone",
                "category",
                "industry"
            ]
            let msg = ""
            for(const req of required){
                if(!user[req] || user[req].toString().trim() === ""){
                    msg = `Please provide your ${req.replace("_"," ")}`
                    break;
                }
            }
            if(msg !== "") return ToastError(msg)
            let image_url = null
            if(logo){
                let file = dataURLtoFile(logo) 
                let fd = new FormData()
                fd.append("file",file)
                let res = await uploadImage(fd)
                image_url = res?.data?.path;
            }
            await updateClient(user)
            ToastSuccess("Changes have been saved")
        }catch(err){
            ToastError(err.msg)
        }
    }

    useEffect(()=>{
        setUser({
            ...load?.data
        })
    },[load])
    
    useEffect(()=>{
        const store = JSON.parse(localStorage.getItem("user"))
        setUserId(store?.client_id?.id)
    },[])

    return(
        <PaperBox padding={"2rem 0rem"}>
            <Container container>
                <Container md={4} item borderRight={`0.1rem solid ${AppColors.offWhite}`}>
                    <Container padding={"0rem 2rem"}>
                        <H1 fontWeight={"bold"} variant="h3" fontSize={"1.069rem"} color={AppColors.black}>Settings</H1>
                    </Container>
                    <Container marginTop={"2rem"}>
                        {
                            SettingsTabList.map((item,i)=>(
                                <CustomButton key={i} 
                                    width="100%"
                                    padding={"1.8rem"}
                                    onClick={()=>{
                                        if(item.comingsoon) return
                                        setTab(item.title)
                                    }}
                                    background={tab === item.title ? AppColors.lightestRed : null}
                                >
                                    <Container flexDirection={"row"}
                                        display={"flex"}
                                        justifyContent={"space-evenly"}
                                    >
                                        <Image 
                                            backgroundimage={tab === item.title ? item.active : item.icon}
                                            height={"3rem"}
                                            width={"3rem"}
                                        />
                                        <Container width="70%">
                                            <H1 fontSize={"0.938rem"} fontWeight={600} variant="h6"
                                                color={AppColors.black}
                                                textAlign={"left"}
                                            >{item.title} {item.comingsoon ? <P variant="span" color={AppColors.red} fontSize={"0.5rem"}>Coming soon</P> : null}</H1>
                                            <P color={AppColors.grey} textAlign={"left"}>{item.description}</P>
                                        </Container>
                                    </Container>
                                </CustomButton>
                            ))
                        }
                    </Container>
                </Container>
                <Container md={8} item>
                    {
                        tab === "Business Info" ? (
                            <Container>
                                    <Container 
                                        display="flex"
                                        flexdirection={"row"} 
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        marginTop={"5rem"}
                                    >
                                        <Container width={"20%"} flexDirection={"row"}
                                            display={"flex"}
                                            justifyContent={"center"}
                                        >
                                            {
                                                user?.image_url ? <Image 
                                                    backgroundimage={user?.image_url}
                                                    width={"6rem"}
                                                    height={"6rem"}
                                                    background={AppColors.red}
                                                    radius={"50%"}
                                                /> : <Avatar sx={styles.avatar} />
                                            }
                                        </Container>
                                        <Container width={"20%"}
                                            display={"flex"}
                                            justifyContent={"center"}
                                            flexDirection={"column"}
                                        >
                                            <CustomButton variant="outlined" border={`1px solid ${AppColors.lightGrey}`}
                                                onClick={openFilePicker}
                                            >
                                                <P fontSize={"0.956rem"} color={AppColors.black}>Upload New</P>
                                            </CustomButton>
                                            <CustomButton onClick={()=>setPlaceholder(null)}>
                                                <P color={AppColors.red}>Remove</P>
                                            </CustomButton>
                                            <input type='file' accept="image/*" ref={upload} style={{display: 'none'}} 
                                                onChange={handleFilePicker}
                                            />
                                        </Container>
                                    </Container>
                                    <Container>
                                        <form>
                                            <Container container justifyContent={"space-around"}
                                                marginTop={"2rem"}
                                            >
                                                <Container item md={5}>
                                                    <FormGroup>
                                                        <Label>Business name *</Label>
                                                        <Input placeholder="Business name" 
                                                            onChange={e=>setUser({...user,name : e.target.value})}
                                                            value={user?.name || ""}
                                                        />
                                                    </FormGroup>
                                                </Container>
                                                <Container item md={5}>
                                                    <FormGroup>
                                                        <Label>Email address *</Label>
                                                        <Input placeholder="Email address" 
                                                            readOnly
                                                            value={user?.email || ""}
                                                        />
                                                    </FormGroup>
                                                </Container>
                                            </Container>
                                            <Container container justifyContent={"space-around"}
                                                marginTop={"2rem"}
                                            >
                                                <Container item md={5}>
                                                    <FormGroup>
                                                        <Label>Phone Number *</Label>
                                                        <Input placeholder="Phone number" 
                                                            onChange={e=>setUser({...user,phone : e.target.value})}
                                                            value={user?.phone || ""}
                                                        />
                                                    </FormGroup>
                                                </Container>
                                                <Container item md={5}>
                                                    <FormGroup>
                                                        <Label>Alternative Phone Number *</Label>
                                                        <Input placeholder="Alternate number" 
                                                            onChange={e=>setUser({...user,reg_number : e.target.value})}
                                                            value={user?.reg_number || ""}
                                                        />
                                                    </FormGroup>
                                                </Container>
                                            </Container>
                                            <Container container justifyContent={"space-around"}
                                                marginTop={"2rem"}
                                            >
                                                <Container item md={5}>
                                                    <FormGroup>
                                                        <Label>Number of outlets *
                                                            <P variant="span" fontSize={"0.5rem"} marginLeft={"0.2rem"}>
                                                                <InfoIcon sx={{color : AppColors.red}} fontSize="0.5rem" />
                                                            </P>
                                                            <P color={AppColors.red} variant="span" fontSize={"0.5rem"}>
                                                                How many outlets do you have?
                                                            </P>
                                                        </Label>
                                                        <Input placeholder="Outlets" 
                                                            onChange={e=>setUser({...user,outlet : e.target.value})}
                                                            value={user?.outlet || ""}
                                                            type="number"
                                                        />
                                                    </FormGroup>
                                                </Container>
                                                <Container item md={5}>
                                                    <FormGroup>
                                                        <Label>Address of outlet registering * </Label>
                                                        <Input placeholder="Address" 
                                                            onChange={e=>setUser({...user,address : e.target.value})}
                                                            value={user?.address || ""}
                                                        />
                                                    </FormGroup>
                                                </Container>
                                            </Container>

                                            <Container container justifyContent={"space-around"}
                                                marginTop={"2rem"}
                                            >
                                                <Container item md={5}>
                                                    <FormGroup>
                                                        <Label>Business description 
                                                            <P variant="span" fontSize={"0.5rem"} marginLeft={"0.2rem"}
                                                            >
                                                                <InfoIcon sx={{color : AppColors.red}} fontSize="0.5rem" />
                                                            </P>
                                                            <P color={AppColors.red} variant="span" fontSize={"0.5rem"}>
                                                                What  is your business all about?
                                                            </P>
                                                        </Label>
                                                        <Input placeholder="Business Description" 
                                                            type="textarea"
                                                            rows={"4"}
                                                            multiline
                                                            onChange={e=>setUser({...user,description : e.target.value})}
                                                            value={user?.description || ""}
                                                        />
                                                    </FormGroup>
                                                </Container>
                                                <Container item md={5}
                                                    display={"flex"}
                                                    alignItems={"center"}
                                                    justifyContent={"center"}
                                                >
                                                    <CustomButton variant="contained"
                                                        mode="danger" variant="contained" width="10rem" marginbottom={"2rem"}
                                                        loading={isLoading || updating}
                                                        disabled={isLoading || updating}
                                                        onClick={handleSubmit}
                                                    >
                                                        <H1 variant="h6" fontWeight={"bold"}>Save</H1>
                                                    </CustomButton>
                                                </Container>
                                            </Container>
                                            
                                        </form>
                                    </Container>
                            </Container>
                        ) : null
                    }
                    {
                        tab === "Security" && menu === "" ? <Container margin="1rem auto"
                            width="95%"
                            border={`0.1rem solid ${AppColors.offWhite}`}
                            borderRadius={"0.5rem"}
                            padding={"1rem"}
                        >
                                <H1 variant="h6" fontWeight={"bold"} fontSize={"1rem"}>Security</H1>
                                <Container marginTop={"0.8rem"}>
                                    {
                                        SECURTY_TAB_LIST.map((item,i)=><Container 
                                            flexDirection={"row"}
                                            display={"flex"}
                                            key={i}
                                            justifyContent={"space-between"}
                                            padding={"1rem 0rem"}
                                            borderBottom={i < 2 ? `0.1rem solid ${AppColors.offWhite}` : null}
                                            alignItems={"center"}
                                        >
                                       
                                            <Container display={"flex"} width="60%"
                                                justifyContent={"space-between"}
                                                alignItems={"center"}
                                            >
                                                <Image 
                                                    backgroundimage={item.icon}
                                                    height={"3rem"} 
                                                    width={"3rem"} 
                                                />
                                                <Container width="80%">
                                                    <H1 fontSize={"0.875rem"}
                                                        fontWeight={"bold"}>{item.title}</H1>
                                                        <P fontSize={"0.813rem"} color={AppColors.grey}>{item.description}</P>
                                                </Container>
                                            </Container>
                                            <CustomButton variant="outlined" border={`1px solid ${AppColors.red}`}
                                                height="2.2rem"
                                                borderradius={"0.5rem"}
                                                width={"5rem"}
                                                onClick={()=>setMenu(item.menu)}
                                            >
                                                <H1 variant="h1" 
                                                    color={AppColors.red} 
                                                    fontWeight={"600"}
                                                    fontSize={"0.938rem"}
                                                >Reset</H1>
                                            </CustomButton>
                                        </Container>)
                                    }
                                </Container>
                        </Container> : null
                    }
                    

                    {
                        tab === "Security" && menu === "RESET_PASSWORD" ? <ResetPassword goBack={()=>setMenu("")}/> : null
                    }
                    {
                       tab === "Security" && menu === "RESET_EMAIL" ? <ResetEmail goBack={()=>setMenu("")} /> : null
                    }

{
                        tab === "Security" && menu === "RESET_PHONE" ? <ResetPhone goBack={()=>setMenu("")} /> : null
                    }

                </Container>
                <ReactCropper open={open} setOpen={()=>setOpen(false)} image={image} updateProfileImage={updateLogo} />
            </Container>
        </PaperBox>
    )
}

export default Settings;