import { Avatar, Button, Collapse, Divider, IconButton, ListItemButton, List,ListItemIcon, ListItemText, Menu, MenuItem, Paper } from "@mui/material";
import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Container, CustomButton, H1, Image, P, PaperBox } from "../../components";
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { AppColors } from "../../components/AppColors";
import Img from "../../images/brand.png"
import { TabList } from "../../components/Constants";
import Logo from "../../images/bikee.png"
import { ExpandLess, ExpandMore, Inbox, Logout, PersonAdd, Settings, StarBorder } from "@mui/icons-material";
import {useDispatch} from 'react-redux'
import { TOGGLE_DRAWER } from "../../actions";
import { apiFunctions } from "../../utils/api";
import { Capitalize, ToastError } from "../../utils/functions";
import { useMutation } from "react-query";
import { openDrawer } from "../../redux/drawerSlice";
import { CREATE_ORDER } from "../../redux/actionTypes";


export const Authorized = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [route,setRoute] = React.useState("")
    const [menu,setMenu] = React.useState("")

    const {mutateAsync,isLoading} = useMutation(apiFunctions.logout)
    const [user,setUser] = React.useState({})

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const logOutHandler = async () => {
        try{
           await mutateAsync()
           localStorage.clear()
            navigate("/login")
        }catch(err){
            localStorage.clear()
            navigate("/login")
        }
    }

    const checkLoggedInUser = () => {
        const token = localStorage.getItem("token")
        if(!token){
            return navigate("/login")
        }
        const store = JSON.parse(localStorage.getItem("user"))
        if(store?.type !== "client") return navigate("/login")
        if(store){
            setUser(store)
        }
    }
    useEffect(()=> {
        checkLoggedInUser()
    },[])

    const goTo = (route,comingsoon) =>{
        if(comingsoon) return
        navigate(route)
    }

    const dispatch = useDispatch()

    const ListItem = ({item,i}) => {
        return(
            <CustomButton
                background={route.includes(item.route) ?  AppColors.lightRed : null}
                display="flex"
                flexdirection={"row"}
                justifycontent={"center"}
                cursor="pointer"
                alignitems={"center"}
                margin={!item.sub_menu ? "1.5rem 0rem" : null}
                fullWidth
                onClick={()=>{
                    if(!item.sub_menu) setMenu("")
                    goTo(item?.route,item?.comingsoon)
                }}
                key={i}
                padding={"0.8rem"}
                borderradius={"0.375rem"}
                marginbottom={"2rem"}
            >
                <Image backgroundimage={route.includes(item.route) ?  item.active : item.icon} 
                    width={"1rem"}
                    height={"1rem"}
                />
                <Container width="70%" marginLeft={"1rem"}>
                    <H1 fontSize={"0.877rem"} textTransform={"capitalize"}
                        fontWeight={route.includes(item.route) ?  'bold' : null}
                        color={route.includes(item.route) ?  AppColors.red : AppColors.grey}
                        textAlign={"left"}
                        sx={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden'
                        }}
                    >{item.name}</H1>
                    {
                        item?.comingsoon ? <P fontSize={"0.5rem"} color={AppColors.red}
                            textAlign={"left"}
                            textTransform={"capitalize"}
                        >Coming Soon</P> : null
                    }
                </Container>
            </CustomButton>
        )
    }
    useEffect(()=>{
        setRoute(location.pathname)
        window.scrollTo(0,0)
    },[location.pathname])

    return(
        <Container container background={AppColors.offWhite}
            direction={"row"}
            justifyContent={"space-between"}
            padding={"0rem 2rem"}
        >
            <Container width="15%">
                <PaperBox
                    margin={"2rem 0rem"}
                >
                    <Container borderBottom={`0.1rem solid ${AppColors.offWhite}`}>
                        <Image
                            margin="auto"
                            backgroundimage={Logo}
                            height={"8rem"}
                            width={"8rem"}
                        />
                    </Container>
                    <Container marginTop={"2rem"} height={"150vh"}>
                        <List>
                            {
                                TabList.map((item,i)=> {
                                    return(
                                        <React.Fragment key={i}>
                                            {
                                                    !item.has_menu ? <ListItem item={item} /> : <React.Fragment>
                                                    <ListItemButton onClick={()=>{
                                                            if(item.has_menu && menu !== item.name){
                                                                return setMenu(item.name)
                                                            }
                                                            if(item.has_menu && menu === item.name){
                                                                return setMenu("")
                                                            }
                                                        }}
                                                        sx={{
                                                            display : "flex",
                                                            justifyContent : "center",
                                                            padding : "0.8rem 1.5rem",
                                                        }}
                                                    >
                                                        <Image backgroundimage={route.includes(item.route) ?  item.active : item.icon} 
                                                            width={"1rem"}
                                                            height={"1rem"}
                                                        />
                                                        <Container width="70%" marginLeft={"1rem"}>
                                                            <H1 fontSize={"0.877rem"} textTransform={"capitalize"}
                                                                fontWeight={route.includes(item.route) ?  'bold' : null}
                                                                color={route.includes(item.route) ?  AppColors.red : AppColors.grey}
                                                                textAlign={"left"}
                                                            >{item.name}</H1>
                                                        </Container>
                                                        {menu === item.name ? <ExpandLess sx={{
                                                            color : AppColors.grey
                                                        }} /> : <ExpandMore color="red" sx={{
                                                            color : AppColors.grey
                                                        }} />}
                                                    </ListItemButton>
                                                    <Collapse in={item.name === menu} timeout="auto" unmountOnExit>
                                                        <List component="div" disablePadding sx={{pl : 2,pr : 2}}>
                                                            {
                                                                item.MENU_LIST.map((menu,index)=>
                                                                    <ListItem item={menu} key={index} />
                                                                )
                                                            }
                                                        </List>
                                                    </Collapse>
                                                </React.Fragment>
                                            }
                                        </React.Fragment>
                                    )
                                })
                            }
                            
                        </List>
                    </Container>
                </PaperBox>
            </Container>
            <Container width="80%">
                <PaperBox padding={"1rem"}
                    margin={"2rem 0rem"}
                    display="flex"
                    align="center"
                    justify="space-between"
                >
                    <CustomButton mode="danger"
                        variant="contained" width="13rem" 
                        margintop={"0.5rem"}
                        onClick={()=>dispatch(openDrawer(CREATE_ORDER))}
                    >
                        <AddIcon />
                        <H1 variant="h6" fontSize={"0.82rem"} fontWeight={"bold"}>Post New Order</H1>
                    </CustomButton>
                    <Container width="50%" direction={"row"} container
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                    >
                        <Container width={"50%"}>
                            <H1 fontWeight={"bold"}>{user?.email}</H1>
                            <P>Wallet balance: N0</P>
                        </Container>
                        <Container width={"20%"} direction={"row"} container
                            alignItems={"center"}
                            justifyContent={"flex-end"}
                        >
                            <IconButton onClick={()=>goTo("/user/settings")}>
                                <Avatar src={user?.image_url} />
                            </IconButton>
                            <IconButton onClick={handleClick}>
                                <KeyboardArrowDownIcon color={AppColors.grey} />
                            </IconButton>


            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                    },
                    '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                    },
                },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={()=>goTo("/user/settings")}>
                <ListItemIcon>
                    <Settings fontSize="small" />
                </ListItemIcon>
                Settings
                </MenuItem>
                <MenuItem onClick={logOutHandler}>
                <ListItemIcon>
                    <Logout fontSize="small" />
                </ListItemIcon>
                Logout
                </MenuItem>
            </Menu>

                        </Container>
                    </Container>
                </PaperBox>
                <Outlet />
            </Container>
        </Container>
    )
}