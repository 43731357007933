import Activity from "../images/activity.png"
import RedActivity from "../images/red-activity.png";
import Wallet from "../images/wallet.png";
import Setting from "../images/setting.png";
import RedSetting from "../images/red-setting.png";
import RedWallet from "../images/red-wallet.png";
import Question from "../images/question-circle.png";
import Profile from "../images/profile.png";
import ActiveProfile from "../images/active-profile.png";
import Security from "../images/security.png";
import ActiveSecurity from "../images/active-security.png";
import Purse from "../images/purse.png";
import Card from "../images/card.png";
import Padlock from "../images/padlock.png";
import Phone from "../images/phone.png";
import Envelope from "../images/envelope.png";
import AddFund from "../images/add-fund.png";
import Transfer from "../images/transfer.png";
import Payment from "../images/payment.png";
import Paystack from "../images/paystack.png";
import Paypal from "../images/paypal.png";
import Quickteller from "../images/quickteller.png";
import Bike from "../images/bike.png";
import YellowLine from "../images/yellow-line.png";
import RedLine from "../images/red-line.png";
import { AppColors } from "./AppColors";

export const TabList = [
    {
        icon : Activity,
        name : "Dashboard",
        route : "dashboard",
        active : RedActivity,
        comingsoon : false
    },
    {
        icon : Activity,
        name : "Menu List",
        route : "menu-list",
        active : RedActivity,
        comingsoon : true
    },
    {
        icon : Wallet,
        name : "Orders",
        route : "orders",
        active : RedWallet,
        comingsoon : false,
        has_menu : true,
        MENU_LIST : [
            {
                icon : Wallet,
                name : "Today's Order",
                route : "orders",
                active : RedWallet,
                comingsoon : false,
                sub_menu : true
            },
            {
                icon : Wallet,
                name : "Order History",
                route : "order-history",
                active : RedWallet,
                comingsoon : false,
                sub_menu : true
            }
        ]
    },
    {
        icon : Wallet,
        name : "Customers",
        route : "customers",
        active : RedWallet,
        comingsoon : false
    },
    {
        icon : Wallet,
        name : "Financials",
        route : "financials",
        active : RedWallet,
        comingsoon : false,
        has_menu : true,
        MENU_LIST : [
            {
                icon : Wallet,
                name : "Wallet",
                route : "financials",
                active : RedWallet,
                comingsoon : false,
                sub_menu : true
            },
            {
                icon : Wallet,
                name : "Invoice",
                route : "invoices",
                active : RedWallet,
                comingsoon : false,
                sub_menu : true
            },
            {
                icon : Wallet,
                name : "Transactions",
                route : "transaction-history",
                active : RedWallet,
                comingsoon : false,
                sub_menu : true
            }
        ]
    },
    {
        icon : Question,
        name : "Integration",
        route : "integrations",
        active : Wallet,
        comingsoon : true
    },
    {
        icon : Setting,
        name : "Notification",
        route : "notifications",
        active : RedSetting,
        comingsoon : true
    },
    {
        icon : Setting,
        name : "Settings",
        route : "settings",
        active : RedSetting,
        comingsoon : false
    },
    
]

export const SettingsTabList = [
    {
        title : "Business Info",
        description : "About your business data",
        icon : Profile,
        active : ActiveProfile,
        comingsoon : false
    },
    {
        title : "Security",
        description : "Manage your account security",
        icon : Security,
        active : ActiveSecurity,
        comingsoon : false
    },
    {
        title : "Financials",
        description : "Manage your wallet account",
        icon : Purse,
        active : Purse,
        comingsoon : true
    },
    {
        title : "Notification",
        description : "Information regarding payment method",
        icon : Card,
        active : Card,
        comingsoon : true
    }
]

export const SECURTY_TAB_LIST = [
    {
        icon : Padlock,
        title : "*********",
        description : "You can reset your password",
        menu : "RESET_PASSWORD"
    },
    {
        icon : Envelope,
        menu : "RESET_EMAIL",
        title : "marianade@chickenrepublic.com",
        description : "Keep your email up-to-date"
    },
    {
        icon : Phone,
        title : "xxxxxxxxx22",
        menu : "RESET_PHONE",
        description : "Change phone number to receive notification"
    }
]

export const WALLET_MENU_LIST = [
    {
        title : "Fund wallet",
        icon : AddFund,
        comingsoon : false
    },
    {
        title : "Transfer Balance",
        icon : Transfer,
        comingsoon : true
    },
    {
        title : "Make Payment",
        icon : Payment,
        comingsoon : true
    }
]

export const WALLET_CARD_LIST = [
    {
        balance : "N 20,150",
        title : "Wallet Balance",
        description : "20% Increase from Last Week",
        background : AppColors.yellow
    },
    {
        balance : "N 16,000",
        title : "Delivery fee to be paid",
        description : "15% Increase from Last Month",
        background : AppColors.blue
    },
    {
        balance : "100,000",
        title : "Cash on Delivery",
        description : "5% Increase from Last Week",
        background : AppColors.lightGreen
    }
]


export const DASHBOARD_CARD_LIST = [
    {
        balance : "N 16,000",
        title : "Cash on delivery",
        description : "15% Increase from Last Month",
        background : AppColors.blue
    },
    {
        balance : "100,000",
        title : "Transfer on delivery",
        description : "5% Increase from Last Week",
        background : AppColors.lightGreen
    }
]

export const LATEST_ORDERS_LIST = [
    {
        id : 1,
        orderID : "#37300478",
        customer : "Akinwale Oladimeji",
        address : "Herbert Marculey Way, Ojo Alaba, Lagos",
        rider : 'James Owolabi',
        fee : 'N900',
        status : 'Pending'
    },
    {
        id : 2,
        orderID : "#373037478",
        customer : "Femi Owowale",
        address : "Herbert Marculey Way, Ojo Alaba, Lagos",
        rider : 'Mike Jackson',
        fee : 'N1000',
        status : 'Pending'
    },
    {
        id : 3,
        orderID : "#37300478",
        customer : "Akinwale Oladimeji",
        address : "Herbert Marculey Way, Ojo Alaba, Lagos",
        rider : 'James Owolabi',
        fee : 'N900',
        status : 'Pending'
    },
    {
        id : 4,
        orderID : "#373037478",
        customer : "Femi Owowale",
        address : "Herbert Marculey Way, Ojo Alaba, Lagos",
        rider : 'Mike Jackson',
        fee : 'N100',
        status : 'In-Transit'
    },
    {
        id : 5,
        orderID : "#37300478",
        customer : "Akinwale Oladimeji",
        address : "Herbert Marculey Way, Ojo Alaba, Lagos",
        rider : 'James Owolabi',
        fee : 'N900',
        status : 'In-Transit'
    },
    {
        id : 6,
        orderID : "#373037478",
        customer : "Femi Owowale",
        address : "Herbert Marculey Way, Ojo Alaba, Lagos",
        rider : 'Mike Jackson',
        fee : 'N1500',
        status : 'Delivered'
    }
]

export const TRANSACTION_LIST =  [
    {
        id : 1,
        orderID : "#49428895",
        transaction_type : "COD",
        delivery_fee : "N58,000.00",
        collected_by : "Kazeem Raheem",
        status : "Paid"
    },
    {
        id : 2,
        orderID : "#49783495",
        transaction_type : "COD",
        delivery_fee : "N58,000.00",
        collected_by : "Wale Rabiu",
        status : "Paid"
    },
    {
        id : 3,
        orderID : "#494467",
        transaction_type : "COD",
        delivery_fee : "N58,000.00",
        collected_by : "Kazeem Raheem",
        status : "Unpaid"
    },
    {
        id : 4,
        orderID : "#4949265",
        transaction_type : "COD",
        delivery_fee : "N58,000.00",
        collected_by : "Femi Tijani",
        status : "Unpaid"
    },
    {
        id : 5,
        orderID : "#4949265",
        transaction_type : "COD",
        delivery_fee : "N58,000.00",
        collected_by : "Femi Tijani",
        status : "Unpaid"
    },
    {
        id : 6,
        orderID : "#49428895",
        transaction_type : "COD",
        delivery_fee : "N58,000.00",
        collected_by : "Kazeem Raheem",
        status : "Paid"
    },
    {
        id : 7,
        orderID : "#49783495",
        transaction_type : "COD",
        delivery_fee : "N58,000.00",
        collected_by : "Wale Rabiu",
        status : "Paid"
    },
    {
        id : 8,
        orderID : "#494467",
        transaction_type : "COD",
        delivery_fee : "N58,000.00",
        collected_by : "Kazeem Raheem",
        status : "Unpaid"
    },
    {
        id : 9,
        orderID : "#4949265",
        transaction_type : "COD",
        delivery_fee : "N58,000.00",
        collected_by : "Femi Tijani",
        status : "Unpaid"
    },
    {
        id : 10,
        orderID : "#4949265",
        transaction_type : "COD",
        delivery_fee : "N58,000.00",
        collected_by : "Femi Tijani",
        status : "Unpaid"
    }
]

export const USER_CATEGORY_LIST = [
    "Individual",
    "Business"
]

export const INDUSTRY_LIST = [
    "Restaurant","Catering","Lounge/Bar","Flowers/Gifts"
]

export const PAYMENT_METHOD_LIST = [
    {
        icon : Paystack,
        title : 'Paystack',
        description : 'Pay with Paystack'
    },
    {
        icon : Paypal,
        title : 'Paypal',
        description : 'Pay with Paypal'
    },
    {
        icon : Quickteller,
        title : 'Quickteller',
        description : 'Pay with Quickteller'
    }
]

export const ALL_TRANSACTION_LIST =  [
    {
        id : 1,
        orderID : "#494288934",
        address : "8, Owoniyi Street, Mike Owo",
        transaction_type : "COD",
        date : "10-01-2012",
        lcda : "Femi Ogunjode",
        delivery_fee : "N58,000.00",
        collected_by : "Kazeem Raheem",
        status : "Paid"
    },
    {
        id : 2,
        orderID : "#49428895",
        address : "7, Owoniyi Street, Mike Owo",
        transaction_type : "COD",
        date : "10-01-2012",
        lcda : "Egbeda",
        delivery_fee : "N58,000.00",
        collected_by : "Kazeem Raheem",
        status : "Paid"
    },
    {
        id : 3,
        orderID : "#49428895",
        address : "7, Owoniyi Street, Mike Owo",
        transaction_type : "COD",
        date : "10-01-2012",
        lcda : "Egbeda",
        delivery_fee : "N58,000.00",
        collected_by : "Kazeem Raheem",
        status : "Paid"
    },{
        id : 4,
        orderID : "#49428895",
        address : "7, Owoniyi Street, Mike Owo",
        transaction_type : "COD",
        date : "10-01-2012",
        lcda : "Ikeja",
        delivery_fee : "N58,000.00",
        collected_by : "Kazeem Raheem",
        status : "Paid"
    },
    {
        id : 5,
        orderID : "#49428895",
        address : "7, Owoniyi Street, Mike Owo",
        transaction_type : "COD",
        date : "10-01-2012",
        lcda : "Alimosho",
        delivery_fee : "N58,000.00",
        collected_by : "Kazeem Raheem",
        status : "Paid"
    },
    {
        id : 6,
        orderID : "#49428895",
        address : "7, Owoniyi Street, Mike Owo",
        transaction_type : "COD",
        date : "10-01-2012",
        lcda : "Ogundele",
        delivery_fee : "N58,000.00",
        collected_by : "Kazeem Raheem",
        status : "Paid"
    },
    {
        id : 8,
        orderID : "#49428895",
        address : "7, Owoniyi Street, Mike Owo",
        transaction_type : "COD",
        date : "10-01-2012",
        lcda : "Egbeda",
        delivery_fee : "N58,000.00",
        collected_by : "Kazeem Raheem",
        status : "Paid"
    },
    {
        id : 9,
        orderID : "#49428895",
        address : "7, Owoniyi Street, Mike Owo",
        transaction_type : "COD",
        date : "10-01-2012",
        lcda : "Egbeda",
        delivery_fee : "N58,000.00",
        collected_by : "Kazeem Raheem",
        status : "Paid"
    },
    {
        id : 10,
        orderID : "#49428895",
        address : "7, Owoniyi Street, Mike Owo",
        transaction_type : "COD",
        date : "10-01-2012",
        lcda : "Egbeda",
        delivery_fee : "N58,000.00",
        collected_by : "Kazeem Raheem",
        status : "Paid"
    },
    {
        id : 11,
        orderID : "#49428895",
        address : "7, Owoniyi Street, Mike Owo",
        transaction_type : "COD",
        date : "10-01-2012",
        lcda : "Egbeda",
        delivery_fee : "N58,000.00",
        collected_by : "Kazeem Raheem",
        status : "Paid"
    },
    {
        id : 12,
        orderID : "#49428895",
        address : "7, Owoniyi Street, Mike Owo",
        transaction_type : "COD",
        date : "10-01-2012",
        lcda : "Egbeda",
        delivery_fee : "N58,000.00",
        collected_by : "Kazeem Raheem",
        status : "Paid"
    }
]

export const ALL_CUSTOMERS_LIST =  [
    {
        id : 1,
        customerID : "#494288934",
        customerName : "Karimu Oluwatolumide",
        address : "8, Owoniyi Street, Mike Owo",
        phone : "08165267876",
        status : "Delivered",
        lcda : "Lekki South"
    },
    {
        id : 2,
        customerID : "#494288934",
        customerName : "Ojo Oladimeji",
        address : "8, Owoniyi Street, Mike Owo",
        phone : "08165267876",
        status : "Delivered",
        lcda : "Owo South"
    },
    {
        id : 3,
        customerID : "#4942808934",
        customerName : "Femi Olawale",
        address : "8, Owoniyi Adsina Street, Mike Owo",
        phone : "08165267876",
        status : "Delivered",
        lcda : "Owo South"
    },
    {
        id : 4,
        customerID : "#49674288934",
        customerName : "Ojo Oladimeji",
        address : "8, Owoniyi Mike Owo Street, Mike Owo",
        phone : "08165267876",
        status : "In-Transit",
        lcda : "Owo South"
    },
    {
        id : 5,
        customerID : "#49478282434",
        customerName : "Femi Oladimeji",
        address : "8, Owoniyi Street, Mike Owo",
        phone : "08165267876",
        status : "Pending",
        lcda : "Owo South"
    },
    {
        id : 6,
        customerID : "#49423288934",
        customerName : "Ojo Oladimeji",
        address : "8, Owoniyi Street, Mike Owo",
        phone : "08165267876",
        status : "Delivered",
        lcda : "Owo South"
    }
]

export const CUSTOMER_ORDER_LIST =  [
    {
        id : 1,
        orderID : "#494288934",
        date : "Jan 12, 2022",
        amount : "N3,250",
        type : "COD",
        status : "Delivered"
    }
]

export const DASHBOARD_STATS = [
    {
        title : 'TOTAL DISTANCE COVERED',
        value : '25 km',
        description : '1.10% Since yesterday',
        icon : RedLine,
        arrow : true
    },
    {
        title : 'CO2 AVOIDED',
        value : '800kg',
        description : '1.10% Since yesterday',
        icon : YellowLine,
        arrow : true
    },
    {
        title : 'TOTAL DELIVERIES',
        value : '25',
        description : 'Total deliveries till date',
        icon : Bike,
        arrow : false
    },
    {
        title : 'ASSIGNED DELIVERIES',
        value : '234',
        description : 'Since yesterday',
        icon : Bike,
        arrow : false
    }
]