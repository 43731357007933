import React from "react"
import { Checkbox, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, InputAdornment, MenuItem, OutlinedInput, Select, Step, StepLabel, Stepper, TextField } from "@mui/material"
import { LinkedButton,H1, Container, CustomStepLabel, CustomButton, P, Input, Label, CustomSelect, Option } from "../../components"
import { useNavigate } from "react-router-dom"
import { AppColors } from "../../components/AppColors"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import { INDUSTRY_LIST, USER_CATEGORY_LIST } from "../../components/Constants"


const Register = () => {
    const navigate = useNavigate()
    const [step,setStep] = React.useState(1)
    const [show,setShow] = React.useState(false)
    const handleSubmit = () => {
        if(step !== 2) return setStep(2)
        return navigate("/confirm-email")
    }

    const goBack = () => {
        setStep(1)
    }

    const handleShow = () => {
        setShow(!show)
    }

    return(
        <Container item md={7}>
            <Container
                justifyContent={"right"}
                display={"flex"}
                alignItems={"center"}
                width="90%"
                marginTop={"2rem"}
                marginBottom={"5rem"}
            >
                <LinkedButton to="/login" color={AppColors.grey}>Login</LinkedButton>
            </Container>
        <Container margin="2rem auto" width={"80%"}>
            <Container>
                <H1 variant="h5" fontWeight={'bold'} textAlign={'center'} fontSize={"1.5rem"}>Sign Up</H1>
                <Stepper activeStep={step} alternativeLabel color="#3FB48D">
                    {["Personal Information","Business Category"].map((label) => (
                    <Step key={label} completed={false}>
                        <CustomStepLabel color="red">{label}</CustomStepLabel>
                    </Step>
                    ))}
                </Stepper>
                <form>
                    {
                        step === 1 ? <React.Fragment>
                        <Container container justifyContent={"space-around"}
                            marginTop={"2rem"}
                        >
                            <Container item md={4}>
                                <FormGroup>
                                    <Label>Full Name</Label>
                                    <Input placeholder="Full Name" />
                                </FormGroup>
                            </Container>
                            <Container item md={4}>
                                <FormGroup>
                                    <Label>Business Name</Label>
                                    <Input placeholder="Business Name" />
                                </FormGroup>
                            </Container>
                        </Container>
                        <Container container justifyContent={"space-around"}
                            marginTop={"2rem"}
                        >
                            <Container item md={4}>
                                <FormGroup>
                                    <Label>Email Address</Label>
                                    <Input type={"email"} placeholder="Email" />
                                </FormGroup>
                            </Container>
                            <Container item md={4}>
                                <FormGroup>
                                    <Label>Phone number</Label>
                                    <Input type={"number"}
                                        placeholder="+234 ..."
                                    />
                                </FormGroup>
                            </Container>
                        </Container>
                        <Container container justifyContent={"space-around"}
                            marginTop={"2rem"}
                        >
                            <Container item md={4}>
                                <FormGroup>
                                    <Label>Create Password</Label>
                                    <Input type={"password"} placeholder="*******" 
                                        endAdornment={
                                            <InputAdornment position="end">
                                              <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleShow}
                                              >
                                                {show ? <VisibilityOff /> : <Visibility />}
                                              </IconButton>
                                            </InputAdornment>
                                          }
                                    />
                                </FormGroup>
                            </Container>
                            <Container item md={4}>
                                <FormGroup>
                                    <Label>Confirm Password</Label>
                                    <Input type={"password"} 
                                        placeholder="******"
                                    />
                                </FormGroup>
                            </Container>
                        </Container>
                    </React.Fragment> : <React.Fragment>
                        <Container container justifyContent={"space-around"}
                                marginTop={"2rem"}
                            >
                                <Container item md={4}>
                                    <FormGroup>
                                        <Label>User Category</Label>
                                        <CustomSelect
                                            value={""}
                                        >
                                            {
                                                USER_CATEGORY_LIST.map((item,i)=><Option value={item} key={i}>{item}</Option>)
                                            }
                                        </CustomSelect>
                                    </FormGroup>
                                </Container>
                                <Container item md={4}>
                                    <FormGroup>
                                        <Label>Industry</Label>
                                        <CustomSelect
                                            value={""}
                                        >
                                            {
                                                INDUSTRY_LIST.map((item,i)=><Option value={item} key={i}>{item}</Option>)
                                            }
                                        </CustomSelect>
                                    </FormGroup>
                                </Container>
                            </Container>
                    </React.Fragment>
                    }
                    <Container container display={"flex"} justifyContent={step === 1 ? "center" : "space-evenly"} alignItems={"center"}
                        direction={step === 1 ? "column" : "row"}
                        marginTop={"3rem"}
                        marginBottom={"2rem"}
                    >
                        {
                            step === 2 ? <CustomButton 
                                variant="outlined" width="10rem" border={`2px solid ${AppColors.red}`}
                                padding={"0.5rem 0"}
                                onClick={goBack}
                            >
                                    <H1 textAlign={"center"} color={AppColors.red}
                                        fontWeight={"bold"}
                                    >BACK</H1>
                                </CustomButton> : null
                        }
                        <CustomButton mode="danger" variant="contained" width={step === 1 ? "20rem" : "10rem"} 
                            onClick={handleSubmit}
                        >
                            {step === 1 ? "Next" : "Sign Up"}
                        </CustomButton>
                    </Container>
                    {
                        step === 2 ? <React.Fragment>
                                <Container
                                    width={"60%"}
                                    margin={"auto"}
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox />
                                        }
                                        label={<P color={AppColors.black} fontSize={"0.949rem"}>Yes! I’d like to receive BIKEE’s weekly newsletter.</P>}
                                    />
                                </Container>
                                <Container
                                    width={"60%"}
                                    margin={"auto auto 3rem auto"} 
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox />
                                        }
                                        label={<P color={AppColors.black} fontSize={"0.949rem"}>I agree to the terms of <LinkedButton color={AppColors.red} to="#">service</LinkedButton> and <LinkedButton color={AppColors.red} to="#">privacy policy</LinkedButton>.</P>}
                                    />
                                </Container>
                        </React.Fragment> : null
                    }
                    <P fontSize={"0.948rem"} textAlign={"center"}>Already have an account? <LinkedButton to="/login" mode="danger">Login</LinkedButton></P>
                </form>
            </Container>
        </Container>
    </Container>
    )
}

export default Register