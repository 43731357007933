import { Grid, Typography } from "@mui/material"
import React from "react"


const Confirmation = () => {
    return(
        <Grid>
            <Typography>
                This is the Login
            </Typography>
        </Grid>
    )
}

export default Confirmation