import React from "react";
import { Container, P, PaperBox,H1, CustomButton,Image, WalletCard, LinkedButton } from "../../../components";
import { AppColors } from "../../../components/AppColors";
import AddIcon from '@mui/icons-material/Add';
import FundWalletIcon from '../../../images/fund-wallet.png'
import { DASHBOARD_STATS, DASHBOARD_CARD_LIST, LATEST_ORDERS_LIST } from "../../../components/Constants";
import { ArrowUpward } from "@mui/icons-material";
import { FUND_WALLET_MODAL } from "../../../components/Modals";
import styles from "./styles";
import { TOGGLE_DRAWER } from "../../../actions";
import {useDispatch} from 'react-redux'

export const Dashboard = () => {
    const [open,setOpen] =  React.useState(false)
    const dispatch = useDispatch()
    return(
        <Container
            container
            display={"flex"}
            justifyContent={"space-between"}
        >
            <Container 
                item
                md={8.5}
            >
                <Container 
                    flexDirection={"row"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                        <PaperBox 
                            width="55%" 
                            padding="2rem" borderradius={"0.8rem"}
                            border={`0.1rem solid ${AppColors.line}`}
                        >
                            <Container 
                                flexDirection={"row"}
                                display="flex"
                                alignItems={"center"}
                                justifyContent={"center"}
                            >
                                <Container>
                                    <H1 fontWeight={"bold"} color={AppColors.grey}
                                        fontSize={"1rem"}
                                    >Welcome, So Fresh Marketplace</H1>
                                    <P color={AppColors.textGrey}>You have created <P variant="span" color={AppColors.black}
                                        fontSize={"0.7rem"}
                                    >12 orders today</P></P>
                                    <CustomButton mode="danger"
                                        variant="contained" width="13rem" 
                                        margintop={"1rem"}
                                        onClick={()=>dispatch(TOGGLE_DRAWER("TOGGLE_CREATE_ORDER"))}

                                    >
                                        <AddIcon />
                                        <H1 variant="h6" fontSize={"0.82rem"} fontWeight={"bold"}>Post New Order</H1>
                                    </CustomButton>
                                </Container>
                                <Container display={"flex"}
                                    flexDirection={"column"}
                                    alignItems={"center"}
                                >
                                    <P  color={AppColors.textGrey}
                                        fontSize={"0.775rem"}
                                        textAlign={"center"}
                                    >Wallet balance</P>
                                    <H1 color={AppColors.black} fontSize={"1.744rem"} 
                                        textAlign={"center"}
                                    fontWeight={"bold"}>N20,150</H1>
                                    <CustomButton width="80%" onClick={()=>setOpen(true)}>
                                        <Container flexDirection={"row"}
                                            display={"flex"}
                                            justifyContent={"center"}
                                            alignItems={"center"}
                                        >
                                            <Image backgroundimage={FundWalletIcon} 
                                                width={"3rem"}
                                                height={"3rem"}
                                                marginRight={"0.6rem"}
                                            />
                                            <P color={AppColors.black} fontSize={"0.934rem"}>Fund Wallet</P>
                                        </Container>
                                    </CustomButton>
                                </Container>
                            </Container>
                        </PaperBox>
                        <Container width={"35%"}>
                            <WalletCard 
                                height="11.2rem"
                                expirySize={"0.584rem"}
                                serialSize={"0.934rem"}
                                nameSize={"0.584rem"}
                                padding={"1rem"}
                                serial={"5339    7642    8674    7245"}
                                name="BUKKAHUT RESTAURANTS - OGUDU OUTLETS"
                                expiry="02/30"
                            />
                        </Container>
                </Container>
                <Container 
                    container
                    display={"flex"}
                    marginTop={"2rem"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    rowGap={1}
                >
                    {
                        DASHBOARD_STATS.map((item,i)=><PaperBox key={i}
                            width={'40%'}
                            padding="2rem" borderradius={"0.8rem"}
                            border={`0.1rem solid ${AppColors.line}`}
                            display={"flex"}
                            justifycontent="space-between"
                        >
                            <Container height={"6rem"}
                                display={"flex"}
                                flexDirection={"column"}
                                justifyContent={"space-between"}
                            >
                                <Container>
                                    <P fontSize={"0.75rem"} color={AppColors.black}>{item.title}</P>
                                    <H1 fontWeight={"bold"} color={AppColors.black} fontSize={"1.425rem"}>{item.value}</H1>
                                </Container>
                                <Container display={"flex"}
                                    flexDirection={"row"}
                                    alignItems={"center"}
                                >
                                   {
                                       item.arrow ?  <ArrowUpward fontSize="small" sx={{
                                            color : AppColors.green
                                        }}/> : null
                                   }
                                    <P fontSize={"0.75rem"} color={AppColors.black}>{item.description}</P>
                                </Container>
                            </Container>
                            <Image 
                                backgroundimage={item.icon}
                                width={"10rem"}
                                height={"5rem"}
                            />
                        </PaperBox>)
                    }
                </Container>
                <PaperBox margin={"2rem 0 2rem 0"} padding={'2rem'}>
                    <Container display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        marginBottom={"2rem"}
                    >
                        <H1 fontWeight={"bold"} color={AppColors.black} fontSize={"0.996rem"}>
                            Latest orders {" - "}
                            <P variant="span" color={AppColors.textGrey}>08/06/2022</P>
                        </H1>
                        <LinkedButton to="/user/orders">
                            <H1 fontWeight={"bold"} fontSize={"0.776rem"} color={AppColors.purple}>View all</H1>
                        </LinkedButton>
                    </Container>
                    

                    <Container display={"flex"} justifyContent={"space-between"}
                            alignItems={"center"}
                        >
                            <Container width={'15%'} padding={"1rem"}>
                                <H1 fontWeight={"bold"} color={AppColors.black} fontSize={"0.775rem"}
                                    sx={styles.textNoWrap}
                                    variant="h6"
                                >Order ID</H1>
                            </Container>
                            <Container width={'15%'} padding={"1rem"}>
                                <H1 fontWeight={"bold"} color={AppColors.black} fontSize={"0.775rem"}
                                    sx={styles.textNoWrap}
                                >Recipient</H1>
                            </Container>
                            <Container width={'15%'} padding={"1rem"}>
                                <H1 fontWeight={"bold"} color={AppColors.black} fontSize={"0.775rem"}
                                    variant="h6"
                                    sx={styles.textNoWrap}
                                >Address</H1>
                            </Container>
                            <Container width={'15%'} padding={"1rem"}>
                                <H1 
                                    variant="h6"
                                    fontWeight={"bold"} 
                                    color={AppColors.black} 
                                    fontSize={"0.775rem"}
                                    sx={styles.textNoWrap}
                                >Bikee Rider</H1>
                            </Container>
                            <Container width={'15%'} padding={"1rem"}>
                                <H1 fontWeight={"bold"} 
                                    variant="h6"
                                    color={AppColors.black} 
                                    fontSize={"0.775rem"}
                                    sx={styles.textNoWrap}
                                >Delivery Fee</H1>
                            </Container>
                            <Container width={'15%'} padding={"1rem"}>
                                <H1 fontWeight={"bold"} 
                                    variant="h6"
                                    color={AppColors.black} 
                                    fontSize={"0.775rem"}
                                    sx={styles.textNoWrap}
                                >Status</H1>
                            </Container>
                        </Container>

                    {
                        LATEST_ORDERS_LIST.map((item,i)=><Container key={i} display={"flex"} borderBottom={i < (LATEST_ORDERS_LIST.length - 1) ? `0.1rem solid ${AppColors.line}` : null} justifyContent={"space-between"}
                            alignItems={"center"}
                        >
                            <Container width={'15%'} padding={"1rem"}>
                                <H1 fontWeight={"bold"} color={AppColors.textGrey} fontSize={"0.775rem"}
                                    sx={styles.textNoWrap}
                                    variant="h6"
                                >{item.orderID}</H1>
                                <P sx={styles.textNoWrap}
                                    fontSize={"0.555rem"}
                                    color={AppColors.textGrey}
                                >4:00PM</P>
                            </Container>
                            <Container width={'15%'} padding={"1rem"}>
                                <H1 fontWeight={"bold"} color={AppColors.textGrey} fontSize={"0.775rem"}
                                    sx={styles.textNoWrap}
                                >{item.customer}</H1>
                            </Container>
                            <Container width={'15%'} padding={"1rem"}>
                                <H1 fontWeight={"bold"} color={AppColors.textGrey} fontSize={"0.775rem"}
                                    variant="h6"
                                    sx={styles.textNoWrap}
                                >{item.address}</H1>
                            </Container>
                            <Container width={'15%'} padding={"1rem"}>
                                <H1 
                                    variant="h6"
                                    fontWeight={"bold"} 
                                    color={AppColors.textGrey} 
                                    fontSize={"0.775rem"}
                                    sx={styles.textNoWrap}
                                >{item.rider}</H1>
                            </Container>
                            <Container width={'15%'} padding={"1rem"}>
                                <H1 fontWeight={"bold"} 
                                    variant="h6"
                                    color={AppColors.textGrey} 
                                    fontSize={"0.775rem"}
                                    sx={styles.textNoWrap}
                                >{item.fee}</H1>
                            </Container>
                            <Container width={'15%'} padding={"1rem"}>
                                {
                                    item.status === "In-Transit" ? <CustomButton background={AppColors.yellow} borderradius={"0.5rem"}>
                                        <P color={AppColors.darkYellow}>{item.status}</P>
                                    </CustomButton> : null
                                }

                                {
                                    item.status === "Delivered" ? <CustomButton background={AppColors.lightLime} borderradius={"0.5rem"}>
                                        <P color={AppColors.lime}>{item.status}</P>
                                    </CustomButton> : null
                                }
                                {
                                    item.status === "Pending" ? <CustomButton background={AppColors.lightRed} borderradius={"0.5rem"}>
                                        <P color={AppColors.red}>{item.status}</P>
                                    </CustomButton> : null
                                }

                            </Container>
                        </Container>
                        )
                    }
                </PaperBox>
            </Container>
            <Container 
                item
                md={3}
            >
                {
                    DASHBOARD_CARD_LIST.map((item,i)=>(
                        <Container key={i} background={item.background}
                            padding={"1rem"}
                            borderRadius={"0.6rem"}
                            marginBottom={"2rem"}
                        >
                            <H1 variant="h6" fontWeight={"bold"} color={AppColors.black} fontSize={"1.322rem"}
                                textAlign={"center"}
                            >{item.balance}</H1>
                            <P fontSize={"0.841rem"} textAlign={"center"}>{item.title}</P>
                            <P fontSize={"0.721rem"} color={AppColors.grey} textAlign={"center"}>{item.description}</P>
                        </Container>
                    ))
                }
                <PaperBox width="100%" margin={"0 0 2rem 0"}>
                    {
                        [
                            {
                                title : "Unassigned deliveries",
                                value : 426
                            },
                            {
                                title : "Ongoing deliveries",
                                value : 426
                            },
                            {
                                title : "Cancelled deliveries",
                                value : 0
                            },
                            {
                                title : "Completed deliveries",
                                value : 34
                            }
                        ].map((item,i)=><Container 
                            key={i} 
                            padding={"2rem"} 
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            borderBottom={i < 3 ? `0.1rem solid ${AppColors.line}` : null}
                        >
                            <P color={AppColors.grey} textAlign={"center"} fontSize={"0.95rem"}>{item.title}</P>
                            <H1 color={AppColors.black} textAlign={"center"} fontSize={"1.425rem"}
                                fontWeight={"bold"}
                            >{item.value}</H1>
                        </Container>)
                    }
                </PaperBox>
                <FUND_WALLET_MODAL open={open} setOpen={setOpen} />
            </Container>
        </Container>
    )
}
