import { ArrowBack } from '@mui/icons-material';
import { Avatar, IconButton } from '@mui/material';
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, CustomButton, H1, P, PaperBox } from '../../../components'
import { AppColors } from '../../../components/AppColors';
import { CUSTOMER_ORDER_LIST} from '../../../components/Constants';
import DataGridDemo from '../../../components/Tables';
import { CUSTOMER_TOOLBAR } from '../../../components/Toolbars';
import styles from './styles';

const CustomerHistory = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const columns = [
        {
            field : "orderID",
            header : "Order ID",
            renderCell : (value) => <H1 fontWeight={"bold"} fontSize={"0.844rem"}>{value}</H1>
        },
        {
            field : "date",
            header : "Date",
            renderCell : (value) => <H1 fontWeight={"bold"} fontSize={"0.844rem"}>{value}</H1>
        },
        {
            field : "amount",
            header : "COD Amount",
            renderCell : (value) => <H1 fontWeight={"bold"} fontSize={"0.844rem"}>{value}</H1>
        },
        {
            field : "type",
            header : "Transaction Type",
            renderCell : (value) => <H1 fontWeight={"bold"} fontSize={"0.844rem"}>{value}</H1>
        },
        {
            field : "status",
            header : "Delivery Status",
            renderCell : (value) => <React.Fragment>
                {
                    value === "In-Transit" ? <CustomButton background={AppColors.yellow} borderradius={"0.5rem"}>
                        <P color={AppColors.darkYellow}>{value}</P>
                    </CustomButton> : null
                }

                {
                    value === "Delivered" ? <CustomButton background={AppColors.lightLime} borderradius={"0.5rem"}>
                        <P color={AppColors.lime}>{value}</P>
                    </CustomButton> : null
                }
                {
                    value === "Pending" ? <CustomButton background={AppColors.lightRed} borderradius={"0.5rem"}>
                        <P color={AppColors.red}>{value}</P>
                    </CustomButton> : null
                }
            </React.Fragment>
        },
        
    ].map((item,i)=>({
        id : i.toString(),
        field: item.field,
        // headerName: item.headerName,
        // description: item.description,
        sortable: false,
        //width: 160,
        flex :1,
        renderCell : (param) => item.renderCell(param.row[item.field]),
        renderHeader: () => (
            <P color={AppColors.textGrey} fontSize={"0.95rem"}>{item.header}</P>
          ),
    }));

    return(
            <Container display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Container width="25%">
                    <PaperBox padding={"2rem"}>
                        <Avatar />
                    </PaperBox>
                </Container>
                <Container width="70%">
                    <PaperBox padding={"2rem"}>
                        <DataGridDemo columns={columns} rows={CUSTOMER_ORDER_LIST}
                            hideFooter={false}
                            Toolbar={()=><CUSTOMER_TOOLBAR title="Customer Order History" />}
                        />
                    </PaperBox>
                </Container>
            </Container>
    )
}

export default CustomerHistory;