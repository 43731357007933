import React from "react"
import { Checkbox, FormControlLabel, FormGroup, FormLabel, Grid, OutlinedInput, Step, StepLabel, Stepper, TextField } from "@mui/material"
import { Link, useNavigate } from "react-router-dom"
import { LinkedButton,H1, Container, CustomStepLabel, CustomButton, P, Input, Label, CheckedBox } from "../../components"
import { AppColors } from "../../components/AppColors"


const ConfirmEmail = () => {

    const navigate = useNavigate()

    const handleSubmit = () => {
        navigate("/register")
    }

    return(
        <Container item md={7}>
            <Container
                justifyContent={"right"}
                display={"flex"}
                alignItems={"center"}
                width="90%"
                marginTop={"2rem"}
                marginBottom={"5rem"}
            >
                <LinkedButton to="/login" color={AppColors.grey}>Login</LinkedButton>
            </Container>
            <Container display={"flex"}
                flexDirection={"column"}
                width={"100%"}
                alignItems={"center"}
                justifyContent={"center"}
            >
                <H1 variant="h5" fontWeight={'bold'} textAlign={'center'} 
                    marginBottom={"1rem"}
                    marginTop={"2rem"}
                    fontSize={"1.5rem"}
                >Verify Your Email</H1>
                    <P textAlign={"center"} color={"#555B6A"} fontSize={"0.948rem"} color={AppColors.grey}>
                    We've sent a link to your email address:</P>
                    <P color={"#FF0000"} textAlign={"center"} fontSize={"0.948rem"}>youremail@gmail.com</P>
                    <Container container display={"flex"} justifyContent={"center"} alignItems={"center"}
                            direction={"column"}
                            marginTop={"2rem"}
                        >
                            <CustomButton mode="danger" variant="contained" width="20rem" marginbottom={"2rem"}
                                onClick={handleSubmit}
                            >
                                Sign Up
                            </CustomButton>
                            <P fontSize={"0.948rem"}>Didn't receive an email? <LinkedButton to="#" mode="danger">Resend</LinkedButton></P>
                        </Container>
            </Container>
        </Container>
    )
}

export default ConfirmEmail