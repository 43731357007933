import { configureStore } from "@reduxjs/toolkit";
import drawer from "./drawerSlice";
import order from "./orderSlice";

const store = configureStore({
    reducer : {
        drawer,
        order
    }
})

export default store;