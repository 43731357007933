import { createSlice } from "@reduxjs/toolkit";

const drawerSlice = createSlice({
    name : "DRAWER",
    initialState : {
        name : "",
        open : false
    },
    reducers : {
        openDrawer : (state,action) => {
            return {...state,name : action.payload,open : !state.open}
        }
    }
})

export const {openDrawer} = drawerSlice.actions
export default drawerSlice.reducer;