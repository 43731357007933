import { ArrowForward } from "@mui/icons-material";
import React, { useEffect } from "react";
import { Container, P, PaperBox,H1, CustomDatePicker, CustomButton, CustomSelect } from "../../../components";
import { AppColors } from "../../../components/AppColors";
import {useDispatch} from 'react-redux'
import {openDrawer} from "../../../redux/drawerSlice"
import { useLocation } from "react-router-dom";
import { MenuItem, Skeleton } from "@mui/material";
import { ORDER_DETAILS } from "../../../redux/actionTypes";
import { useFetchOrders, useFetchTodaysOrder } from "../../../utils/api";
import numeral from "numeral";
import { Capitalize } from "../../../utils/functions";
import moment from "moment";
import { changeOrderHandler } from "../../../redux/orderSlice";

const Orders = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const [page,setPage] = React.useState(1)
    const [client_id,setClientId] = React.useState(null)
    const [orders,setOrders] = React.useState([])
    const [loading,setLoading] = React.useState(false)
    
    const {
        data : todaysOrdersData,
    } = useFetchTodaysOrder(client_id,!location.pathname.includes("/user/order-history"))

    const {
        data : ordersData,
    } = useFetchOrders(client_id,location.pathname.includes("/user/order-history"))

    const getUserInfo = () => {
        let user = JSON.parse(localStorage.getItem("user"))
        setClientId(user?.client_id?.id)
    }


    const mapArrayToState = () => {
        setLoading(true)
        if(todaysOrdersData?.data?.data && Array.isArray(todaysOrdersData?.data?.data) && 
            !location.pathname.includes("/user/order-history")
        ){
            page > 1 ? setOrders([...orders,todaysOrdersData?.data?.data]) : setOrders(todaysOrdersData?.data?.data)
            return setLoading(false)
        }
        if(ordersData?.data?.data && Array.isArray(ordersData?.data?.data) && 
            location.pathname.includes("/user/order-history")
        ){
            page > 1 ? setOrders([...orders,ordersData?.data?.data]) : setOrders(ordersData?.data?.data)
            setLoading(false)

        }
    }

    useEffect(()=>{
        mapArrayToState()
    },[todaysOrdersData,ordersData])
    useEffect(()=>{
        getUserInfo()
    },[])

    return(
        <Container>
            <PaperBox padding="2rem">
                <Container borderBottom={`0.1rem solid ${AppColors.red}`}
                    paddingBottom={"1rem"}
                >
                    {
                        location.pathname.includes("/user/order-history") ?  (
                            <Container flexDirection={"row"}
                                display={"flex"}
                                alignItems={"center"}
                                width="48%"
                                justifyContent={"space-between"}
                            >
                                <H1 variant="h6" fontWeight={"bold"} fontSize={"1.069rem"}>All Orders</H1>  
                                <Container 
                                    direction={"row"} 
                                    container
                                    display={"flex"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                    width="75%"
                                >
                                    <CustomDatePicker width="10rem" />
                                    <ArrowForward 
                                        fontSize='small'
                                        sx={{
                                            color : AppColors.grey
                                        }} 
                                    />
                                    <CustomDatePicker width="10rem" />
                                </Container>
                            </Container>
                        ) : (
                            <Container flexDirection={"row"}
                                display={"flex"}
                                alignItems={"center"}
                                width="48%"
                                justifyContent={"space-between"}
                            >
                            <H1 variant="h6" fontWeight={"bold"} fontSize={"1.069rem"}>Today's Orders</H1>  
                            <CustomSelect sx={{
                                width : "8rem",
                                height : "3rem"
                            }}>
                                <MenuItem>All</MenuItem>
                                <MenuItem>Pending</MenuItem>
                                <MenuItem>In-Transit</MenuItem>
                            </CustomSelect>
                        </Container>
                        )
                    }
                </Container>
                <Container marginTop={"2rem"}>
                    {
                        loading && page === 1 ? <React.Fragment>
                           {
                               [..."123"].map(item=> <Skeleton 
                                variant='rectangle'
                                height={"10rem"}
                                style={{marginBottom : "1rem"}}
                                key={item}
                            />)
                           }
                    </React.Fragment> : <React.Fragment>
                        {
                            orders && Array.isArray(orders) && orders.length > 0  ? orders.map((item,i)=><CustomButton fullWidth padding={"0"} key={i} margin={"0 0 2rem 0"}
                                onClick={()=>{
                                    dispatch(changeOrderHandler(item))
                                    dispatch(openDrawer(ORDER_DETAILS))
                                }}
                            >
                                <PaperBox display={"flex"}
                                    justify="space-between"
                                    width="100%"
                                    border={`0.1rem solid ${AppColors.line}`}
                                    padding={'1.5rem 2rem'}
                                >
                                    <Container height={"8rem"}
                                        display={"flex"}
                                        flexDirection={"column"}
                                        justifyContent={"space-between"}
                                        width={"18%"}
                                    >
                                        <Container>
                                            <H1 fontWeight={"bold"} fontSize={"0.96rem"} textAlign={"left"}>ID: #{item?.order_ref}</H1>
                                            <P color={AppColors.grey} fontSize={"0.823rem"} textAlign={"left"}>{item?.no_of_items ? numeral(item?.no_of_items).format("0,0") : "0"} Items</P>
                                        </Container>
                                        <Container>
                                            <P color={AppColors.textGrey} textAlign={"left"}>Customer name</P>
                                            <H1 fontWeight={"bold"} fontSize={"0.823rem"} textAlign={"left"}>{`${item?.customer?.first_name ? Capitalize(item?.customer?.first_name) : ""} ${item?.customer?.last_name ? Capitalize(item?.customer?.last_name) : ""}`.trim()}</H1>
                                        </Container>
                                    </Container>
                                    <Container height={"8rem"}
                                        display={"flex"}
                                        flexDirection={"column"}
                                        justifyContent={"space-between"}
                                        width={"18%"}
                                    >
                                        <Container>
                                            <P color={AppColors.textGrey} textAlign={"left"}>Destination</P>
                                            <H1 fontWeight={"bold"} fontSize={"0.823rem"} textAlign={"left"}
                                            >{item?.delivery_address}</H1>
                                        </Container>
                                        <Container>
                                            <P color={AppColors.textGrey} textAlign={"left"}>Amount to collect</P>
                                            <H1 color={AppColors.red} fontSize={"0.823rem"} textAlign={"left"} fontWeight={"bold"}>₦ {item?.amount_to_collect ? numeral(item?.amount_to_collect).format("0,0") : null} <P variant="span" color={AppColors.grey} fontWeight={"normal"}>{item?.payment_method}</P></H1>
                                        </Container>
                                    </Container>
                                    <Container height={"8rem"}
                                        display={"flex"}
                                        flexDirection={"column"}
                                        justifyContent={"space-between"}
                                        width={"18%"}
                                    >
                                        <Container>
                                            <P color={AppColors.textGrey} textAlign={"left"}>Date</P>
                                            <H1 fontWeight={"bold"} fontSize={"0.823rem"} textAlign={"left"}>{item?.created_at ? moment(item.created_at).format("MMM DD, YYYY") : null}</H1>
                                        </Container>
                                    </Container>
                                    <Container height={"8rem"}
                                        display={"flex"}
                                        flexDirection={"column"}
                                        justifyContent={"space-between"}
                                        width={"18%"}
                                    >
                                        <H1 fontWeight={"bold"} fontSize={"0.823rem"} textAlign={"left"}>Rider : {item?.rider ? Capitalize(item.rider.toString()) : null}</H1>
                                        
                                        {item?.status ? <Container backgroundColor={AppColors.lightLime} borderRadius={"0.5rem"} width="6rem"
                                            padding={"0.2rem"}
                                        >
                                            <P color={AppColors.lime}>Delivered</P>
                                        </Container> : null}
                                    </Container>
                                </PaperBox>
                            </CustomButton>) : <Container display={"flex"} height={"20rem"}
                                    justifyContent={"center"}
                                ><H1 fontSize={"2rem"} color={AppColors.grayBorder}>No record found</H1></Container>
                        }
                    </React.Fragment>}
                </Container>
            </PaperBox>
        </Container>
    )
}

export default Orders;