import { ArrowBack } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, CustomButton, H1, P, PaperBox } from '../../../components'
import { AppColors } from '../../../components/AppColors';
import { ALL_CUSTOMERS_LIST} from '../../../components/Constants';
import DataGridDemo, { CustomPagination } from '../../../components/Tables';
import { CUSTOMER_TOOLBAR } from '../../../components/Toolbars';
import { useFetchCustomers } from '../../../utils/api';
import { Capitalize } from '../../../utils/functions';
import styles from './styles';

const Customers = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const backHandler = () => {
        navigate(-1)
    }
    const [page,setPage] = React.useState(1)
    const [pageCount,setPageCount] = React.useState(1)
    const [client_id,setClientId] = React.useState(null)
    const onPageChange = (value) => {
        setPage(value)
    }

    const {
        data : customers,
        isLoading,
        isFetching
    } = useFetchCustomers(client_id,page)

    const getUserInfo = () => {
        let user = JSON.parse(localStorage.getItem("user"))
        setClientId(user?.client_id?.id)
    }

    useEffect(()=>{
        if(!customers) return
        setPage(customers?.data?.current_page)
        setPageCount(customers?.data?.last_page)
    },[customers])

    useEffect(()=>{
        getUserInfo()
    },[])

    const RenderPagination = () => {
        return(
            <CustomPagination 
                page={page} 
                onPageChange={onPageChange} 
                pageCount={pageCount}
            />
        )
    }


    const columns = [
        // {
        //     field : "customerID",
        //     header : "Customer ID",
        //     renderCell : (value) => <H1 fontWeight={"bold"} fontSize={"0.844rem"}>{value}</H1>
        // },
        {
            field : "customerName",
            header : "Customer Name",
            renderCell : (row) => <H1 fontWeight={"bold"} fontSize={"0.844rem"}
            sx={styles.textNoWrap}
        >{`${row?.first_name ? Capitalize(row?.first_name) : ""} ${row?.last_name ? Capitalize(row?.last_name) : ""}`.trim()}</H1>
        },
        {
            field : "email",
            header : "Email Address",
            renderCell : (row) => <React.Fragment>
                {/* {
                    value === "In-Transit" ? <CustomButton background={AppColors.yellow} borderradius={"0.5rem"}>
                        <P color={AppColors.darkYellow}>{value}</P>
                    </CustomButton> : null
                }

                {
                    value === "Delivered" ? <CustomButton background={AppColors.lightLime} borderradius={"0.5rem"}>
                        <P color={AppColors.lime}>{value}</P>
                    </CustomButton> : null
                }
                {
                    value === "Pending" ? <CustomButton background={AppColors.lightRed} borderradius={"0.5rem"}>
                        <P color={AppColors.red}>{value}</P>
                    </CustomButton> : null
                } */}
                <CustomButton background={AppColors.lightLime} borderradius={"0.5rem"}>
                        <P color={AppColors.lime}>{row?.email}</P>
                    </CustomButton> 
            </React.Fragment>
        },
        {
            field : "phone",
            header : "Phone Number",
            renderCell : (row) =><H1 fontWeight={"bold"} fontSize={"0.844rem"}>{row?.phone}</H1>
        },
        {
            field : "address",
            header : "Address",
            renderCell : (row) =><H1 fontWeight={"bold"} fontSize={"0.844rem"}
                sx={styles.textNoWrap}
            >{row?.address?.formatted_address ? Capitalize(row?.address?.formatted_address) : null}</H1>
        },
        // {
        //     field : "lcda",
        //     header : "LCDA",
        //     renderCell : (value) =><H1 fontWeight={"bold"} fontSize={"0.844rem"}>{value}</H1>
        // }
    ].map((item,i)=>({
        id : i.toString(),
        field: item.field,
        // headerName: item.headerName,
        // description: item.description,
        sortable: false,
        //width: 160,
        flex :1,
        renderCell : (param) => item.renderCell(param.row),
        renderHeader: () => (
            <P color={AppColors.textGrey} fontSize={"0.95rem"}>{item.header}</P>
          ),
    }));

    return(
        <PaperBox padding={"2rem"}>
            <Container>
                <DataGridDemo columns={columns} rows={customers?.data?.data && Array.isArray(customers?.data?.data) ? customers?.data?.data : []}
                    hideFooter={false}
                    Toolbar={()=><CUSTOMER_TOOLBAR title="All Customers" />}
                    loading={isLoading || isFetching}
                    CustomPagination={RenderPagination}
                />
            </Container>
        </PaperBox>
    )
}

export default Customers;