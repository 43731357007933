import React from 'react'
import { MenuItem} from '@mui/material'
import {Container,H1,CustomSelect,Input, CustomDatePicker,LinkedButton, CustomButton, P} from './index'
import { ArrowForward } from '@mui/icons-material';
import { AppColors } from './AppColors';
import SearchIcon from '@mui/icons-material/Search';

export const TRANSACTION_LIST_TOOLBAR = () => (
    <Container marginTop={"4rem"} container
        marginBottom={"1rem"}
        direction={"row"} 
        container
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
    >
        <Container 
            direction={"row"} 
            container
            display={"flex"}
            alignItems={"center"}
            width={"70%"}
            justifyContent={"space-between"}
        >
                <H1 variant='h6' fontWeight={"bold"} fontSize={"1.069rem"}>Transaction</H1>
                <CustomDatePicker />
                <ArrowForward 
                    fontSize='small'
                    sx={{
                        color : AppColors.grey
                    }} 
                />
                <CustomDatePicker />
                <CustomSelect sx={{
                    width : "8rem",
                    height : "3rem"
                }}>
                    <MenuItem>Already Paid</MenuItem>
                    <MenuItem>COD</MenuItem>
                    <MenuItem>Transfer</MenuItem>
                </CustomSelect>
                <CustomSelect sx={{
                    width : "8rem",
                    height : "3rem"
                }}>
                    <MenuItem>Paid</MenuItem>
                </CustomSelect>

        </Container>
        <LinkedButton to="/user/transaction-history">
            <H1 variant='h6' fontWeight={"bold"} fontSize={"1.069rem"} color={AppColors.textGrey}>View all</H1>
        </LinkedButton>
    </Container>
)

export const TRANSACTION_HISTORY_TOOLBAR = (props) => (
    <Container
        container
        marginBottom={"1rem"}
        direction={"row"} 
        container
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
    >
        <Container 
            direction={"row"} 
            container
            display={"flex"}
            alignItems={"center"}
            width={"60%"}
            justifyContent={"space-between"}
        >
                <H1 variant='h6' fontWeight={"bold"} fontSize={"1.069rem"}>{props.title}</H1>
                <CustomDatePicker />
                <ArrowForward 
                    fontSize='small'
                    sx={{
                        color : AppColors.grey
                    }} 
                />
                <CustomDatePicker />
                <CustomSelect sx={{
                    width : "8rem",
                    height : "3rem"
                }}>
                    <MenuItem>Already Paid</MenuItem>
                    <MenuItem>COD</MenuItem>
                    <MenuItem>Transfer</MenuItem>
                </CustomSelect>

        </Container>
        <CustomButton
            variant="outlined" width="10rem" border={`2px solid ${AppColors.line}`}
            padding={"0.5rem 0"} 
        >
            <H1 variant='h6' fontWeight={"bold"} fontSize={"0.901rem"} color={AppColors.textGrey}>
                EXPORT - CSV
            </H1>
        </CustomButton>
    </Container>
)

export const CUSTOMER_TOOLBAR = (props) => (
    <Container
        container
        marginBottom={"1rem"}
        direction={"row"} 
        container
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
    >
        <Container 
            direction={"row"} 
            container
            display={"flex"}
            alignItems={"center"}
            width={"50%"}
            justifyContent={"space-between"}
        >
                <H1 variant='h6' fontWeight={"bold"} fontSize={"1.069rem"}>{props.title}</H1>
                <Input 
                    placeholder='Search for anything'
                    startAdornment={
                        <SearchIcon 
                            fontSize='medium' sx={{color : AppColors.grey}}
                        />
                    }
                />

        </Container>
    </Container>
)

export const LASTEST_ORDERS_TOOLBAR = () => {
    return(
        <Container 
            display="flex"
            justifyContent={"space-between"}
            alignItems={"center"}
        >
            <H1 variant='h6' fontWeight={"bold"}>Latest orders - <P variant="span" color={AppColors.textGrey}>08/06/2022</P></H1>
            <LinkedButton to="#">
                <H1 variant='h6' fontWeight={"bold"}>View All</H1>
            </LinkedButton>
        </Container>
    )
}
